div.pagespeed {
  .head {
  }
  .urls {
    margin: 40px 0;

    .url {
      display: flex;

      .url-field {
        flex-grow: 1;
      }

      .delete {
        margin: 20px 30px 0 30px;
        cursor: pointer;
      }
    }
  }
  .actions {
    display: flex;

    .submit-button {
      .plus-icon {
        margin-bottom: 5px;
        margin-right: 5px;
      }
    }

    .save-button {
      width: 250px;
      margin-left: 20px;
    }

    .saving-error {
      margin-left: 20px;
      margin-top: 10px;
      font-size: 80%;
      color: red;
    }
  }
  .saved-icon {
    margin-top: 10px;
    margin-left: 10px;
  }
  .error-message {
    font-size: 80%;
    color: red;
    min-height: 18px;
    display: block;
  }
  .button-disabled {
    background-color: #bacab8;
  }
}
