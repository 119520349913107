.top-bar {
  user-select: none;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
  height: 22px;
  background-color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 8px 10px;
  padding-left: 5px;

  .left-side,
  .middle,
  .right-side {
    width: 33%;
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  div.left-side {
    justify-content: flex-start;

    div.menu {
      border-radius: 9999px;
      cursor: pointer;
      display: flex;
      justify-content: center;

      &:hover {
        background-color: #d9d9d96b;
      }
      span {
        padding: 5px;
      }
    }

    div.logo {
      font-size: 28px;
      font-weight: 700;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: left;
      margin-left: 15px;

      img {
        width: 80px;
      }
    }
  }

  div.middle {
    gap: 0;
    justify-content: center;

    button {
      color: #828282;
      appearance: none;
      border: none;
      background-color: transparent;
      font-size: 16px;

      &:first-of-type {
        padding-right: 0;
      }
    }

    button:hover {
      color: black;
      cursor: pointer;
    }
  }

  div.right-side {
    // width: 20%;
    justify-content: flex-end;
    padding-right: 5px;
  }

  .account-menu {
    position: relative;
    display: flex;
    justify-content: center;

    > span {
      cursor: pointer;
      filter: opacity(0.7);
      scale: 1.1;

      &:hover {
        filter: opacity(1);
      }
    }

    &.open {
      filter: opacity(1);

      span {
        filter: opacity(1);
      }
    }

    .menu-options {
      position: absolute;
      top: 30px;
      right: 0;
      padding: 0;
      margin: 0;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 200px;
      background-color: white;
      border-radius: 4px;
      border: 1px solid #c2c1bc;
      box-shadow: 4px 4px 6px 2px rgb(0 0 0 / 10%);
      list-style: none;

      li.sub-menu {
        position: relative;
        justify-content: space-between;

        p {
          margin: 0;
        }

        div {
          box-shadow: 4px 4px 6px 2px rgb(0 0 0 / 5%);
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          position: absolute;

          background-color: white;
          width: 100%;
          right: 100%;
          top: 0;

          border: 1px solid #c2c1bc;
          border-radius: 3px;
        }
      }

      li {
        display: flex;
        align-items: center;
        width: calc(100% - 16px);
        padding: 5px 8px;
        height: 24px;

        &:hover {
          cursor: pointer;
          background-color: #f3f3f3;
        }

        span {
          scale: 0.8;
        }

        &:not(:last-of-type) {
          border-bottom: 1px solid #e6e6e5;
        }
      }

      a {
        text-decoration: none;
        color: black;
      }

      .custom {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .log-out-button {
        p {
          margin: 0;
        }

        &:hover {
          color: #df1f1f;
          background-color: white;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .top-bar {
    .left-side {
      width: 40%;

      &>.header {
        display: none;
      }
    }

    .middle {
      display: none;
    }

    div.right-side {
      width: 100%;

      .organizations-menu {
        width: 70%;
      }
    }
  }
}

.organizations-menu {
  position: relative;
  width: 50%;

  div.input-header {
    padding: 0.4rem 0.5rem; 
    font-weight: 400;
    font-size: 11px;
    position: absolute;
    width: calc(100% - 1rem);
    display: flex;

    label {
      color: #9b9b9b;
    }

    span.error-message {
      margin-left: auto;
      color: #dc3545;
    }
  }

  .pick-organization {
    z-index: 1;
    height: 1.9rem;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #dddcd7;
    display: flex;
    flex-direction: column;
    cursor: default;

    &.open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border: 1px solid #c2c1bc;
    }

    &:hover {
      border: 1px solid #c2c1bc;
    }

    div.picked {
      flex-grow: 1;
      display: flex;
      padding: 0 0.5rem;
      font-size: 13px;
      font-weight: 700;
      align-items: center;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    span.arrow {
      position: absolute;
      right: 4px;
      margin-top: 4px;
      font-size: 22px;
      transition: transform 0.2s ease-in-out;
    }

    span.arrow.rotate {
      transform: rotateZ(180deg);
    }
  }

  div.drop-down-organizations {
    box-shadow: 4px 4px 3px rgb(0 0 0 / 5%);

    &::-webkit-scrollbar {
      display: none;
    }

    padding: 10px 15px;

    -ms-overflow-style: none;
    scrollbar-width: none;
    position: absolute;
    border-radius: 4px;
    width: calc(100% - 30px);
    top: calc(2.9rem - 15px);
    left: 0px;
    overflow: hidden;
    background-color: white;
    z-index: 2;
    max-height: 12.3rem;
    border: 1px solid #c2c1bc;
    overflow-y: scroll;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 0;

    span.subtitle-text {
      margin-top: 20px;
      margin-bottom: 5px;
      display: flex;
    }

    button.add-organization {
      font-weight: 700;
      font-size: 14px;
      display: flex;
      align-items: center;
      padding: 0.1em 0.3em;
      margin: 0 auto;

      &:hover {
        color: #567153;
        border-radius: 3px;
      }

      span.enlarge {
        font-weight: 400;
        margin-top: -3px;
        margin-right: 3px;
      }
    }

    ul {
      list-style-type: none;
      font-size: 14px;
      padding: 0;
      margin: 0;

      li {
        border-bottom: 1px solid #f7f6f1;
        height: 2.5rem;
        display: flex;
        align-items: center;
        cursor: default;

        .material-icons-outlined.check-circle {
          margin-right: 7px;
          margin-top: -2px;
          &.checked {
            color: #749770;
          }
          &.unchecked {
            color: #9b9b9b;
          }
        }
        span.organization-name {
          overflow: hidden;
          text-overflow: ellipsis;
          margin-top: -8px;
        }

        &:hover {
          .material-icons-outlined.check-circle {
            &.unchecked {
              color: #575757;
            }
          }
        }

        &:last-child {
          border: 0;
        }
      }
    }
  }
}