.table {
  border-collapse: collapse;
  display: block;
  height: 230px;
  overflow-y: scroll;

  .table__header {
    display: flex;
    align-items: center;
  }

  th {
    position: relative;
    background-color: #739570;
    color: white;
    font-size: 11px;
    height: 35px;
    padding: 0 22px 0 10px;
    border-right: 2px solid white;
    padding-left: 5px;

    span.material-icons-outlined.arrow {
      font-size: 20px;
      cursor: pointer;

      &.transparent {
        opacity: 0.6;
      }
    }

    &:last-child {
      border-right: none;
    }
  }
}

.resizer {
  /* Displayed at the right side of column */
  position: absolute;
  top: 10px;
  right: 5px;
  // width: 5px;
  cursor: col-resize;
  user-select: none;
}

.table__container {
  border: 1px solid #dddcd7;
  border-radius: 4px;
  min-width: 580px;
}

.table tbody {
  tr {
    &:nth-child(even) td {
      background-color: #f2f1f2;
    }
  }

  td {
    position: relative;
    height: 20px;
    font-size: 11px;
    font-weight: 500;

    &::before {
      content: ' ';
      visibility: hidden;
    }

    &.align-right {
      text-align: end;
    }

    span {
      position: absolute;
      inset: 0;
      top: 4px;
      padding: 0 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
