form.container-column.sign-in {
  margin: 75px 4rem;
  margin-bottom: 30px;

  div.credentials {
    margin-bottom: auto;
  }

  button.submit-button {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
  }

  span.server-error {
    color: #dc3545;
    font-size: 13px;
    margin-bottom: 10px;
  }
}

div.sign-in-box {
  background-color: white;
  width: 29rem;
  height: 36rem;
  border-radius: 12px;
  padding: 3rem;
  padding-top: 2rem;
  margin-bottom: 2rem;
}

@media only screen and (max-width: 767px) {
  div.sign-in-box {
    margin: 0;
    padding: 3rem 0;

    .title-container {
      padding: 0 20px;
    }

    .sign-in {
      margin: 75px 0;
      padding: 0 20px;
    }
  }

  .page-column header {
    grid-template-columns: repeat(3, auto);
  }

  .container-grow {
    // overflow: hidden;
  }
}
