div.page-404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -11rem;

  span.title {
    font-weight: 400;
    font-size: 180px;
    color: #739570;
  }

  .submit-button {
    margin-top: 30px;
  }
}
