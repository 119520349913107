div.input-field {
  overflow: hidden;
  height: 2.9rem;
  border: 1px solid #dddcd7;
  border-radius: 4px;
  margin: 0.4rem 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
  max-width: 45rem;

  &:is(.success) {
    background-color: #acd9a81d;

    & .input-header {
      background-color: #acd9a81d;
    }

    &  input {
      background-color: #acd9a81d;
    }
  }

  &.disabled {
    background-color: rgba(239, 239, 239, 0.3);
  }

  &.focused {
    border: 1px solid #c2c1bc;
  }

  &.error {
    border: 1px solid #dc3545;
  }

  &.success {
    border: 1px solid #739570;
  }

  &.info {
    border: 1px solid #ec942c	;
  }

  &:focus-within .input-header {
    .error-message, .info-message {
      overflow: visible;
      text-overflow: unset !important;
      background: white;
      position: absolute;
      left: 0;
    }
  }

  div.input-header {
    font-weight: 400;
    font-size: 11px;
    width: 100%;
    display: flex;
    background-color: white;

    &.disabled {
      background-color: rgba(239, 239, 239, 0.3);
    }

    label {
      color: #9b9b9b;
      margin-right: 7px;
      padding: 0.4rem 0.5rem;
      width: -moz-available;
      width: -webkit-fill-available;
      width: fill-available;
      max-width: 30vw;
      min-width: 3rem;
    }

    span.error-message {
      margin-left: auto;
      color: #dc3545;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 0.4rem 0.5rem;
      height: max-content;

      &:hover {
        overflow: visible;
        text-overflow: unset !important;
        background: white;
        position: absolute;
        right: 0;
      }
    }

    span.info-message {
      margin-left: auto;
      color: #ec942c	;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 0.4rem 0.5rem;
      height: max-content;

      &:hover {
        overflow: visible;
        text-overflow: unset !important;
        background: white;
        position: absolute;
        right: 0;
      }
    }
  }

  div.input-container {
    display: flex;
  }

  input {
    flex-grow: 1;
    display: flex;
    padding: 0 0.5rem;
    font-size: 13px;
    font-weight: 700;
    border: 0;
    width: 100%;

    &:focus {
      outline: none;
    }
  }
}

div.drop-down-input-field-with-image {
  background-color: white;
  width: 100%;
  height: 3.5rem;
  border: 1px solid #dddcd7;
  border-radius: 4px;
  margin: 0.4rem 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: default;

  &.focused {
    border: 1px solid #c2c1bc;
  }

  &.open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-color: #b8b7b4;
  }

  &.error {
    border: 1px solid #dc3545;
  }

  div.input-header {
    padding: 0.4rem 0.5rem;
    font-weight: 400;
    font-size: 11px;
    position: absolute;
    width: calc(100% - 1rem);
    display: flex;
    top: -27px;

    label {
      color: #9b9b9b;
    }

    span.error-message {
      margin-left: auto;
      color: #dc3545;
    }
  }

  span.arrow {
    position: absolute;
    right: 0;
    margin-top: 21px;
    margin-right: 4px;
    font-size: 22px;
  }

  div.info {
    padding-left: 15px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .value {
      width: calc(100% - 1rem);
      flex-grow: 1;
      padding: 0 0.5rem;
      padding-left: 20px;
      font-size: 14px;
      font-weight: 700;
      border: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      outline: none;
      border-radius: 4px;
      cursor: default;
      display: flex;
      justify-content: center;
      flex-direction: column;

      span.subtitle {
        color: #616161;
        font-size: 13px;
      }
    }
  }

  input.value {
    height: 100%;
    width: calc(100% - 1rem - 15px);
    flex-grow: 1;
    padding: 0 0.5rem;
    padding-left: calc(0.5rem + 15px);
    font-size: 14px;
    font-weight: 700;
    border: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    outline: none;
    border-radius: 4px;
    cursor: default;
  }

  ul.drop-down-options {
    background-color: white;
    z-index: 2;
    padding: 0;
    margin: 0;
    position: absolute;
    left: -1px;
    top: calc(3.5rem + 1px);
    width: 100%;
    list-style-type: none;
    border: 1px solid #b8b7b4;
    border-top: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    max-height: 12rem;
    overflow-y: auto;

    li {
      display: flex;
      align-items: center;
      height: 3.3rem;
      font-size: 14px;
      opacity: 0.7;
      margin: 0 0.5rem;
      border-bottom: 1px solid #dddcd7;
      background: white;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.selected {
        opacity: 1;
        color: black;
      }

      &:last-child {
        border-bottom: 0;
      }

      &:hover {
        background: white;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(249, 249, 249, 1) 20%,
          rgba(255, 255, 255, 1) 100%
        );
      }
    }
  }
}

div.drop-down-input-field {
  background-color: white;
  width: 100%;
  height: 2.9rem;
  border: 1px solid #dddcd7;
  border-radius: 4px;
  margin: 0.4rem 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: default;

  &.focused {
    border: 1px solid #c2c1bc;
  }

  &.open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-color: #b8b7b4;
  }

  &.disabled {
    opacity: 0.6;
  }

  &.error {
    border: 1px solid #dc3545;
  }

  div.input-header {
    padding: 0.4rem 0.5rem;
    font-weight: 400;
    font-size: 11px;
    position: absolute;
    width: calc(100% - 1rem);
    display: flex;

    label {
      color: #9b9b9b;
    }

    span.error-message {
      margin-left: auto;
      color: #dc3545;
    }
  }

  span.arrow {
    position: absolute;
    right: 0;
    margin-top: 12px;
    margin-right: 4px;
    font-size: 22px;
  }

  .value {
    width: calc(100% - 1rem);
    flex-grow: 1;
    padding: 0 0.5rem;
    padding-top: 1.4rem;
    font-size: 14px;
    font-weight: 700;
    border: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    outline: none;
    border-radius: 4px;
    cursor: default;
  }

  input.value {
    padding-top: 1rem;
  }

  ul.drop-down-options {
    background-color: white;
    z-index: 2;
    padding: 0;
    margin: 0;
    position: absolute;
    left: -1px;
    top: calc(2.9rem + 1px);
    width: 100%;
    list-style-type: none;
    border: 1px solid #b8b7b4;
    border-top: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    max-height: 12rem;
    overflow-y: auto;

    li {
      display: flex;
      align-items: center;
      height: 2.2rem;
      font-size: 14px;
      margin: 0 0.5rem;
      border-bottom: 1px solid #dddcd7;
      background: white;
      color: #9b9b9b;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.selected {
        color: black;
      }

      &:last-child {
        border-bottom: 0;
      }

      &:hover {
        background: white;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(249, 249, 249, 1) 20%,
          rgba(255, 255, 255, 1) 100%
        );
      }
    }
  }
}

a.form-link {
  width: 100%;
  margin-top: 0.3rem;
  font-weight: 400;
  font-size: 12px;
  color: #739570;
  text-decoration: none;

  cursor: pointer;

  &:hover {
    color: #6a8a67;
  }
}

div.or {
  width: 100%;
  overflow: hidden;
  text-align: center;
  padding: 10px;
  margin-bottom: 1.4rem 0;
  color: #9b9b9b;
  font-size: 14px;

  &:before,
  &:after {
    content: '';
    display: inline-block;
    width: 50%;
    margin: 0 2.5rem 0 -55%;
    vertical-align: middle;
    border-bottom: 2px solid #f7f6f1;
  }

  &:after {
    margin: 0 -55% 0 2.5rem;
  }
}

div.with-google {
  background-image: url(../images/google-icon.png);
}

div.with-microsoft {
  background-image: url(../images/microsoft-icon.png);
}

div.with-google, div.with-microsoft {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  width: 100%;
  height: 2.9rem;
  border: 1px solid #dddcd7;
  border-radius: 4px;

  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: 20px center;
  cursor: pointer;

  &:hover {
    border: 1px solid #c9c8c3;
  }
}

.form-choice {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  
}

.submit-button {
  text-decoration: none;
  width: 16rem;
  height: 2.6rem;
  background-color: #739570;
  margin-top: auto;
  border: 0;
  border-radius: 4px;
  color: white;
  font-weight: 700;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  &:focus {
    outline-color: #6a8a67;
  }

  &:hover {
    color: white;
  }

  &.disabled {
    background-color: #bacab8;
    cursor: not-allowed;
  }

  &.inverted {
    background-color: white;
    color: #739570;
    border: 1px solid #739570;
  }
}

div.drop-down-select {
  font-size: 13px;
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 3px;
  }

  div.select {
    width: 100%;
    height: 2.9rem;
    border: 1px solid #dddcd7;
    border-radius: 4px;
    margin: 0.4rem 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    cursor: default;

    span.arrow {
      position: absolute;
      right: 0;
      margin-top: 5px;
      margin-right: 4px;
      font-size: 22px;
    }

    div {
      padding: 0.4rem 0.5rem;
    }

    &:hover {
      border: 1px solid #c2c1bc;
    }

    &.error {
      border: 1px solid #dc3545;
    }
  }

  ul.drop-down-options {
    padding: 0;
    margin: 0;
    position: absolute;
    left: -1px;
    top: calc(2.9rem + 1px);
    width: 100%;
    list-style-type: none;
    border: 1px solid #dddcd7;
    border-top: 0;
    border-radius: 4px;

    li {
      display: flex;
      align-items: center;
      height: 2.2rem;
      padding: 0 0.2rem;
      margin: 0 0.3rem;
      border-bottom: 1px solid #dddcd7;
      background: white;
      color: #9b9b9b;

      &.selected {
        color: black;
      }

      &:last-child {
        border-bottom: 0;
      }

      &:hover {
        background: white;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(249, 249, 249, 1) 20%,
          rgba(255, 255, 255, 1) 100%
        );
      }
    }
  }
}

div.checkbox-input {
  width: 100%;
  background-color: #f7f6f1;
  border: 1px solid #dddcd7;
  height: 3.1rem;
  display: flex;
  align-items: center;
  font-size: 13px;
  cursor: pointer;

  &.error {
    border-color: #dc3545;

    &:hover {
      border-color: #dc3545;
    }
  }

  &:hover {
    border-color: #c4c3be;
  }

  input[type='checkbox'] {
    cursor: pointer;
    margin-left: 10px;
    -webkit-appearance: none;
    width: 17px;
    height: 17px;
    background-color: #f7f6f1;
    border-radius: 4px;
    border: 2px solid #c4c3be;
    position: relative;

    &:checked::before {
      content: ' ';
      position: absolute;
      left: 2px;
      top: 2px;
      height: calc(100% - 4px);
      width: calc(100% - 4px);
      border-radius: 1px;
      background-color: #9b9b9b;
    }
    &:focus {
      outline: none;
    }
  }

  label {
    cursor: pointer;
    margin-left: 15px;
  }
}

div.form-tip {
  background-color: #f7f6f1;
  padding: 1.5rem 2rem;
  border-radius: 7px;

  h3 {
    margin: 0;
  }

  p {
    color: #9b9b9b;
    margin: 0;
    margin-top: 10px;
    font-size: 12px;
  }
}

div.actions-drop-down {
  user-select: none;
  border: 1px solid #dddcd7;
  border-radius: 4px;
  font-size: 10px;
  font-weight: 700;
  padding: 5px;

  &:hover {
    border-color: #c2c1bc;
  }
  &.open {
    border-color: #c2c1bc;
  }
}

input.search-bar {
  height: 1rem;
  border: 1px solid #dddcd7;
  border-radius: 4px;
  background-color: transparent;
  min-width: 12rem;
  padding: 7px 10px;

  &:focus {
    border: 1px solid #c2c1bc;
    outline: none;
  }
}

.form-error {
  color: #dc3545;
  font-weight: 400;
  font-size: 0.8rem;
}

.form-success {
  color: #739570;
  font-weight: 400;
  font-size: 0.8rem;
}
