@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-Bold.ttf');
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: 'Inter';
  src: url('./fonts/Inter-Regular.ttf');
  font-style: normal;
  font-weight: 400;
}

input,
button {
  font-family: Inter, 'Times New Roman', Times, serif;
}

html {
  font-family: Inter, 'Times New Roman', Times, serif;
  height: 100%;
  width: 100%;

  body {
    height: 100%;
    width: 100%;
    margin: 0;
    background-color: #f7f6f1;

    #root {
      height: 100%;
      width: 100%;
    }
  }
}
