div.pop-up {
  z-index: 999;
  width: 34rem;
  height: 25rem;
  background-color: white;
  position: absolute;
  left: 50%;
  top: calc(50% - 3rem);
  transform: translate(-50%, -50%);
  border-radius: 4px;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.18);
  display: flex;
  flex-direction: column;
  padding: 1.4rem;
  max-height: 25rem;
  overflow: auto;

  &.error {
    width: 30rem;
    height: max-content;

    h2 {
      display: flex;
      align-items: center;
      justify-content: left;

      span.material-icons-outlined {
        color: #d96249;
        margin-right: 5px;
      }
    }

    span.text {
      margin-top: 1rem;
      font-weight: 400;
      text-align: left;
      margin-left: 1.8rem;
    }

    button.link {
      margin-top: 10px;
      margin-left: 1.8rem;
      text-align: left;
    }

    span.details {
      margin-top: 5px;
      font-weight: 400;
      text-align: left;
      margin-left: 1.8rem;
      font-size: 12px;
      color: #9b9b9b;
    }
  }

  span.material-icons-outlined.x {
    width: 2rem;
    text-align: right;
    font-size: 30px;
    opacity: 0.6;
    margin-left: auto;
    cursor: pointer;
    position: absolute;
    right: 9px;
    top: 9px;

    &:hover {
      opacity: 1;
    }
  }

  span.material-icons.check {
    font-size: 200px;
    text-align: center;
    color: #739570;
  }

  span.text {
    margin-top: 2rem;
    font-weight: 700;
    text-align: center;
  }

  .report-name-input-field-container {
    padding: 0 10px;
  }

  .confirmation-actions {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;

    button {
      margin: 10px;
      text-decoration: none;
      width: 16rem;
      height: 2.6rem;
      background-color: #739570;
      border: 0;
      border-radius: 4px;
      color: white;
      font-weight: 700;
      font-size: 15px;
      display: flex;
      justify-content: center;
      align-items: center;

      cursor: pointer;

      &:focus {
        outline-color: #6a8a67;
      }

      &:hover {
        color: white;
      }
    }

    .cancel-button {
      background-color: #eee;
      color: black;

      &:hover {
        color: #666;
      }
    }
  }
}

.confirmation-pop-up {
  height: auto !important;
  text-align: left !important;

  .text-container {
    padding: 15px;
  }
}
