div.save-bar {
  position: absolute;
  background-color: white;
  width: calc(100% - 6rem);
  height: 4rem;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding: 0 3rem;
  box-shadow: 0 11px 16px 9px rgba(0, 0, 0, 0.18);

  div.save-button {
    display: flex;
    align-items: center;
    margin-left: auto;
    width: 10rem;
    position: relative;

    button.submit-button {
      margin: 0;
      width: 10rem;
    }

    span.material-icons-outlined.saved-icon {
      position: absolute;
      left: -30px;
      font-size: 20px;
    }

    div.spinner-border {
      width: 1rem;
      height: 1rem;
      font-size: 12px;
    }
  }
}

div.word-groups {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  min-height: 30rem;
  margin-bottom: 4rem;

  div.names {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
    border-right: 1px solid #dddcd7;
    overflow: auto;

    span.material-icons-outlined.back-arrow {
      font-size: 30px;
    }

    div.header {
      margin-top: 2rem;
      display: flex;
      align-items: center;

      button.submit-button {
        margin: 0;
        margin-left: auto;
        margin-right: 20px;
        font-size: 10px;
        width: 3.5rem;
        height: 20px;
        display: flex;
        padding-right: 5px;

        span.enlarge {
          margin-top: -3px;
          margin-right: 2px;
        }
      }

      span.group-name {
        font-size: 20px;
        font-weight: 700;
      }
    }

    ul {
      margin-top: 20;
      margin-left: 0;
      margin-right: 0;
      padding: 0;
      list-style-type: none;
      margin-bottom: 40px;

      li {
        padding: 0 20px;
        border-bottom: 1px solid #dddcd7;
        height: 2.5rem;
        display: flex;
        align-items: center;
        cursor: default;
        display: flex;

        span.name {
          cursor: pointer;
        }

        input {
          font-size: 16px;
          width: 100%;
          border: 1px solid #dddcd7;
          padding: 5px 5px;
          border-radius: 4px;
          outline: none !important;

          &:focus {
            border: 1px solid #739570;
          }

          &.error {
            border-color: salmon;
          }
        }

        span.material-icons-outlined.edit {
          margin-left: auto;
          font-size: 18px;
          cursor: pointer;
        }
        span.material-icons-outlined.delete {
          font-size: 18px;
          cursor: pointer;
        }

        &:first-child {
          border-top: 1px solid #dddcd7;
        }

        &.selected {
          font-weight: 700;
          background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(193, 222, 190, 0.23991603477328427) 100%
          );
        }
      }
    }
  }

  div.keywords {
    overflow: auto;
    flex-grow: 3;
    flex-basis: 0;
    padding: 3rem;

    .header_container {
      display: flex;
      justify-content: space-between;

      button {
        appearance: none;
        border: none;
        background-color: transparent;

        cursor: pointer;
        border-radius: 3px;
        margin-bottom: 10px;
        padding: 5px;

        &:hover {
          background-color: #dddcd766;
        }

        span.edit {
          margin-bottom: 0;
        }
      }
    }

    div.included {
      span {
        display: flex;
        font-size: 20px;
        margin-bottom: 10px;
      }
    }

    div.excluded {
      margin-top: 3rem;
      span {
        display: flex;
        font-size: 20px;
        margin-bottom: 10px;
      }
    }
  }
}
