.destination-wrapper .guide {
  top: 38px;
  width: 37vw;

  &:not(.show) {
    display: none;
  }
}

.destination-wrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;

  &:has(.show) {
    width: 48%;
  }

  & > h2 {
    margin-bottom: 20px;
  }

  .back-arrow {
    &:hover {
      cursor: pointer;
    }
  }

  .submit-button {
    align-self: flex-start;
    margin: 5px 0;
  }

  .dropdown-wrapper {
    height: 50vh;
    padding: 0 5px;

    & h2 {
      font-size: 20px;
      font-weight: 400;
    }
  }

  .destination-category {
    border: 0;
    margin-bottom: 0;

    .destination-category-info {
      display: flex;
      align-items: center;
      gap: 0.4rem;

      h2 {
        font-size: 20px;
        font-weight: 400;
      }

      & span {
        scale: 0.9;
        &:hover {
          cursor: pointer;
        }
      }
    }

    .destinations {
      padding: 12px 0;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 1rem;

      .destination-button {
        cursor: pointer;
        width: 8rem;
        height: 8rem;
        background-color: white;
        border-radius: 4px;
        border: 1px solid #dddcd7;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 0.5rem 0.7rem;
        overflow: hidden;

        &:hover:not(.disabled) {
          border-color: #c2c1bc;
        }

        &:first-child {
          margin-left: 0;
        }

        .info {
          flex-grow: 2;
          flex-basis: 0;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          padding: 15px 0;

          .spinner-border {
            border-width: 0.2em;
            width: 1rem;
            height: 1rem;
          }

          img {
            max-width: 90px;
            max-height: 40px;
            margin-bottom: 12px;
            width: inherit;
          }
          span.name {
            font-size: 13px;
            font-weight: 400;
            text-align: center;
          }

          .default {
            color: #739570;
          }
        }

        button.link {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-grow: 0.5;
          flex-basis: 0;
          border-top: 1px solid #f7f6f1;

          .enlarge {
            margin-bottom: 5px;
            margin-right: 3px;
          }
        }
      }
      .destination-button.selected {
        border-color: #739570;
        background-color: #acd9a81d;
      }
    }
  }

  .destination-preview-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.3rem;
    .destination-preview-container {
      background-color: #acd9a81d;
      border: 1px solid #dddcd7;
      border-color: #739570;
      border-radius: 3px;
      padding: 4px 10px;
      max-width: 50vw;
      min-width: 2rem;
      width: -moz-available;
      width: -webkit-fill-available;
      width: fill-available;
      min-height: calc(42px - 10px);

      .preview-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        user-select: none;

        .preview-info {
          display: flex;
          align-items: center;
          gap: 0.4rem;
        }

        img {
          max-width: 90px;
          max-height: 40px;
          width: inherit;
        }
      }

      .preview-body {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-top: 1px solid #dddcd7;

        .preview-body__item {
          display: flex;
          align-items: center;
          gap: 0.4rem;

          .date {
            color: #739570;
          }

          .true {
            color: #739570;
          }
          .false {
            color: rgb(227, 93, 93);
          }
        }
      }
    }
  }
}

.account-selection-wrapper,
.credential-form-wrapper {
  .option-header {
    display: flex;
    align-items: center;
    gap: 0.4rem;
    margin: 10px 0;

    h2 {
      font-size: 20px;
      font-weight: 400;
    }

    & span {
      scale: 0.9;
      cursor: pointer;
    }

    &:has(+ p) {
      margin-bottom: 0;
    }

    & + p {
      color: #858383;
      font-size: .9rem;
      margin-top: 0;
    }
  }

  .account-selection-body {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;

    .option {
      border: 1px solid #dddcd7;
      border-radius: 3px;
      padding: 4px 10px;
      max-width: 40vw;
      width: calc(100% - 22px);

      &.disabled {
        opacity: 0.7;
        color: #c8c7c7;
      }

      &.selected {
        border-color: #739570;
        background-color: #acd9a81d;
      }

      &:not(.disabled):hover {
        cursor: pointer;
        border-color: #739570;
      }

      .option-body {
        border-top: 1px solid #dddcd7;
      
        .error {
          color: red;
        }

        & .selected-file {
          color: #739570;
          padding: 2px;

          &:hover {
            color: #617d5e;
          }
        }
      }
    }
  }
}

.credential-form-wrapper {
  max-width: 90%;
}
