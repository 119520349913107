:root {
  --bar-color: #fff;
  --bar-color-transparent: rgba(255, 255, 255, 0.5);
}

.middle {
  display: flex;
  gap: 0.2rem;
}

.bar {
  width: 3px;
  height: 20px;
  display: inline-block;
  transform-origin: bottom center;
  border-radius: 20px;
  background-color: var(--bar-color);
  animation: loader 0.8s linear infinite;
  // box-shadow:5px 10px 20px inset rgba(255,23,25.2);
}

.bar1 {
  animation-delay: 0.1s;
}
.bar2 {
  animation-delay: 0.2s;
}
.bar3 {
  animation-delay: 0.3s;
}
.bar4 {
  animation-delay: 0.4s;
}

@keyframes loader {
  0% {
    transform: scaleY(0.1);
    background: var(--bar-color);
  }
  50% {
    transform: scaleY(1);
    background: var(--bar-color-transparent);
  }
  100% {
    transform: scaleY(0.1);
    background: transparent;
  }
}
