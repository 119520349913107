#modal-root {
  .modal {
    position: fixed;
    top: 50%;
    left: 50%;

    width: fit-content;
    height: fit-content;
    // height: 70%;

    transform: translate(-50%, -50%) scale(0);

    background-color: #fff;
    padding: 3rem;

    border-radius: 10px;

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    z-index: 1000;

    transition: all 150ms linear;

    display: flex;

    &.half-screen {
      width: 50%;
    }

    &.pop {
      transform: translate(-50%, -50%) scale(1);
    }

    .close-button {
      background: none;
      border: none;
      background-color: transparent;

      font-size: 2.5em;

      position: absolute;

      top: 0;
      left: 5px;

      cursor: pointer;
    }

    @media only screen and (max-width: 767px) {
      width: 90% !important;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .overlay {
    background-color: rgba(0 0 0 /0.3);

    position: absolute;
    inset: 0;
    z-index: 999;

    transition: all 150ms linear;

    display: none;

    &.show {
      display: block;
    }
  }
}

// =======================================================
