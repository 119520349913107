.navigation-header {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  transition: gap 0.05s linear;
  cursor: pointer;

  &:hover {
    gap: 0.5rem;
  }
}

p,
span {
  &.error-message {
    margin-left: auto;
    color: #dc3545;
  }
}

span, p {
    &.success {
        color: #739570;
    }

    &.warn {
        color: #ec942c;
    }
    
    &.error {
        color: #dc3545;
    }

    &.underline {
        text-decoration: underline;
    }

    &.bold {
        font-weight: bold;
    }

    &.nm {
        margin: 0;
    }
}