div.container-column {
  &.create-organization {
    margin: 0 calc(7rem - 10px);
    margin-top: 30px;
    margin-bottom: 0;

    button.link {
      color: #9b9b9b;
    }
  }
}

div.create-organization-box {
  background-color: white;
  width: calc(35rem - 20px);
  margin-top: 1rem;
  height: 33rem;
  border-radius: 12px;
  padding: 0 10px;
  padding-top: 2rem;
  padding-bottom: 5rem;
  margin-bottom: 2rem;

  .form-tip {
    margin: 3rem 1.8rem;
    margin-bottom: 0;
  }
}
