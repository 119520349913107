div.sidebar {
  display: flex;
  flex-direction: column;
  z-index: 100;
  padding-top: 10px;
  padding-bottom: 2rem;
  // transition: width 0.2s ease-in-out;

  div.logo {
    display: none;
  }

  .collapsed-sidebar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
      font-size: 20px;
    }

    .icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 15px 0;
      cursor: pointer;
    }

    .icon-container:hover {
      background-color: #f3f3f3;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &.open {
    overflow-y: auto;
    width: 14rem;
  }

  &.collapsed {
    width: 44px;
  }

  .demo-tag {
    background-color: #739570;
    color: white;
    border-radius: 3px;
    padding: 2px 4px;
    font-weight: normal;
    margin-right: 20px;
  }

  span.material-icons-outlined.arrow {
    user-select: none;
    position: absolute;
    font-size: 37px;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    opacity: 0.5;
    width: 2.4rem;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;

    &:hover {
      opacity: 0.7;
    }

    &.rotate {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  position: relative;
  background-color: #fff;
  box-shadow: -12px 0px 16px 9px rgba(0, 0, 0, 0.18);

  .header {
    display: flex;

    div.user-icon {
      margin-left: auto;
      position: relative;
      user-select: none;

      .material-icons.user {
        cursor: pointer;
        font-size: 40px;
      }

      ul.menu {
        z-index: 2;
        font-family: Inter, 'Times New Roman', Times, serif;
        position: absolute;
        right: -11px;
        top: 40px;
        font-size: 13px;
        padding: 4px 0px;
        border-radius: 4px;
        background-color: white;
        border: 1px solid #dddcd7;
        display: flex;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.18);
        flex-direction: column;
        list-style-type: none;
        width: 10rem;

        li.menu-item {
          display: flex;
          align-items: left;
          justify-content: left;
          cursor: pointer;
          border-bottom: 1px solid #dddcd7;
          padding: 6px 18px;

          a {
            text-decoration: none;
            color: black;
          }

          &:last-child {
            border-bottom: none;
          }

          span.logout {
            color: #dc3545;
            display: flex;
          }
        }

        span.material-icons.logout-icon {
          margin-left: 3px;
          font-size: 13px;
        }

        span.material-icons.launch-icon {
          margin-left: 3px;
          font-size: 13px;
          color: #666;
        }
      }
    }
  }

  .log-out-button {
    width: 100%;
    height: 2.6rem;
    border: 1px solid #dddcd7;
    border-radius: 4px;
    background-color: white;
    font-size: 14px;
    font-weight: 700;
    color: #739570;
    cursor: pointer;

    &:hover {
      border: 1px solid #c9c8c3;
    }
  }

  ul.navigation {
    margin: 0;
    padding: 0;
    padding-left: 0rem;
    list-style-type: none;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    // gap: .5rem;

    .guide-header + .guide {
      position: fixed;
      width: 50vw;
      max-width: calc(100vw - 40px);

      a {
        color: #739570;
      }

      top: 38px;
      bottom: 0;
      left: 100%;
      z-index: 2;

      background-color: #fff;
      box-shadow: 0 11px 16px 9px rgba(0, 0, 0, 0.1);

      transition: transform 0.2s ease-in-out;
      transform: translateX(100%);

      padding: 2rem;
      overflow-y: scroll;

      &.show {
        transform: translateX(-100%);
      }

      .close-button {
        background-color: #fff;
        border-radius: 9999px;
        border: none;

        cursor: pointer;

        font-size: 1.5rem;

        position: fixed;
        top: 10px;
        left: calc(32px - 6px);

        display: flex;
        align-items: center;
        gap: 0.3rem;

        &::after {
          content: 'Close';
          font-size: 1rem;
          color: #000;
          transform: scaleX(0);
          transform-origin: left;
          transition: all 0.05s ease;
          margin-top: 0.25rem;
          text-decoration: underline;
        }

        &:hover::after {
          transform: scaleX(1);
        }
      }

      .step-heading {
        display: flex;
        align-items: center;
        gap: 1rem;
      }

      .step-heading p {
        background-color: #739570;
        color: #fff;
        font-weight: bold;
        font-size: 0.7rem;

        padding: 10px 20px;
        border-radius: 30px;

        width: max-content;
        text-wrap: nowrap;
      }

      ol {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        li {
          line-height: 30px;
        }

        li a {
          text-decoration: none;
        }

        li a span {
          vertical-align: bottom;
          margin-bottom: 0.25rem;
        }

        code {
          background-color: rgba(0, 0, 0, 0.04);
          border-radius: 5px;
          padding: 0.2em 0.5em;
        }

        .img-item {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          gap: 1rem;
          margin-bottom: 1rem;

          img {
            border-radius: 5px;
            border: 1px solid #dfe1e5;
            width: 100%;
            height: auto;

            &:is(.small) {
              width: 50%;
            }
          }
        }
      }
    }

    .main-navigation {
      &.active {
        background-color: #edeaea;
      }

      & > div {
        padding-left: 12px;
      }

      &.setup-collapsed,
      &.reports-collapsed,
      &.custom-reports-collapsed,
      &.demo-collapsed,
      &.static {
        &:hover {
          background-color: #f3f3f3;
        }
      }
    }

    li.main-navigation {
      padding: 15px 0;
      padding-left: 0px;
      position: relative;
      cursor: pointer;

      a {
        text-decoration: none;
        color: black;
      }

      .material-icons,
      .material-icons-outlined {
        height: 100%;
        vertical-align: middle;
        font-size: 20px;
        margin-right: 10px;
      }
    }
    .report-container {
      width: 100%;
      padding-bottom: 100px;
      cursor: pointer;

      .options-container {
        .report-controls {
          position: relative;

          .viewmode-switch,
          .report-delete {
            font-weight: normal;

            &:hover {
              font-weight: bold;
            }
          }

          .controls-menu {
            position: absolute;
            background-color: white;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            top: 25px;
            right: 10px;
            border-radius: 3px;
            border: 1px solid #c2c1bc7d;
            z-index: 9999;
            width: 150px;
            box-shadow: 1px 1px 4px 1px #80808033;

            .option {
              display: flex;
              align-items: center;
              font-weight: normal;
              width: calc(100% - 20px);
              padding: 7px 10px;
              border-bottom: 1px solid #e6e6e5;

              &:hover {
                background-color: #c2c1bc3d;
              }
            }
          }
        }
      }

      li.active-report {
        padding: 10px 12px;
        display: flex;
        // justify-content: flex-start;
        justify-content: space-between;

        &:has(> .options-controls) {
          justify-content: space-between;
        }

        &:not(:has(.options-controls)) {
          > span:nth-child(1) {
            margin: 0;
          }
        }

        &.open {
          font-weight: 600;
        }

        &.collapsed {
          + ul {
            display: none;
          }
        }

        + .full-screen {
          margin-top: 1rem;
        }

        + ul {
          list-style: none;
          width: 100%;
          padding: 0;

          li.report-page {
            padding: 16px 7px;
            padding-left: 1rem;
            margin: 0;
            display: flex;
            justify-content: space-between;

            > input {
              font-family: Inter, 'Times New Roman', Times, serif;
              font-size: 15px;
              font-weight: 700;

              outline: none;
              border: none;
              background-color: transparent;
              margin: 0;
              padding: 0;

              &.error {
                color: #dc3545;
              }
            }

            > p {
              margin: 0;
            }

            &:first-of-type {
              margin-top: 10px;
            }

            &:hover {
              background-color: #d9d9d929;
            }
          }

          li.report-page.active {
            background-color: #d9d9d9be;
            font-weight: normal;
          }
        }
      }

      .btn-container {
        margin-top: calc(0.5rem + 10px);
        width: 100%;
        display: flex;
        justify-content: center;
        border-top: 1px solid rgb(204, 204, 204);

        .pb-btn {
          display: flex;
          align-items: center;
          justify-content: center;

          width: 100%;
          height: 100%;
          border: none;
          padding: 10px;
          cursor: pointer;
          background-color: transparent;
          font-size: 15px;

          &:hover {
            background-color: #d9d9d929;
          }
        }
      }
    }

    li.setup-expanded,
    li.demo-expanded,
    li.reports-expanded,
    li.custom-reports-expanded {
      ul.sub-navigation {
        display: block;

        li {
          margin: 0;
          padding: 15px 0 15px calc(20px + 1rem);

          &:has(.demo-tag) {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }

        li:hover {
          background-color: #f3f3f3;
        }
      }

      .setup-header,
      .demo-header,
      .guide-header,
      .reports-header,
      .custom-reports-header {
        margin-bottom: 0.5rem;
      }
    }

    li.setup-collapsed,
    li.demo-collapsed,
    li.reports-collapsed,
    li.custom-reports-collapsed {
      ul.sub-navigation {
        display: none;
      }
    }

    .setup-header,
    .demo-header,
    .guide-header,
    .reports-header,
    .custom-reports-header,
    .active-report {
      display: flex;
      align-items: center;
      justify-content: space-between;

      div {
        display: flex;
        align-items: center;
      }

      span {
        transition: transform 0.2s ease-in-out;
      }

      span.rotate {
        transform: rotateZ(90deg);
      }
    }

    li.active {
      background-color: #d9d9d9be;
    }

    ul.sub-navigation {
      padding-left: 0;
      list-style-type: none;
      li {
        padding-left: 20px;
      }
    }
  }

  .warning-icon {
    color: red;
  }
}

@media only screen and (max-width: 767px) {
  div.sidebar {
    &.open {
      div.logo {
        display: flex;
        padding-left: 12px;
        align-items: center;
        justify-content: left;
        font-size: 28px;
        font-weight: 700;
        padding-bottom: 2rem;

        img {
          width: 80px;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    &.collapsed {
      width: 0;
    }

    .collapsed-sidebar {
      display: none;
    }
  }
}
@media screen and (max-width: 767px) {
  div.sidebar ul.navigation .guide-header + .guide {
    padding: 2rem 20px;
  }
}

@media only screen and (max-width: 550px) {
  div.sidebar {
    &.open {
      width: 100vw;
    }
  }
}
