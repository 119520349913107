.picker-wrapper {
    max-width: 100%;
    width: clamp(80%, 100px, 100%);
    display: grid;
    grid-gap: 1rem;
    gap: 1rem;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
    grid-template-columns: 1fr max-content 1fr;
}