.guide-container {
  /**position: relative;
    padding: 2rem;
    height:100vh;
    isolation: isolate;

    overflow-y: hidden;
    overflow-x: hidden;

    div:has(+ button) {
        input {
            color: rgba(0 0 0 / .6)
        }
        
        + button {
            background-color: transparent;
            border: none;
            cursor: pointer;
        }
    }

    div > p {
        color:rgba(0, 0, 0, 0.6);
        font-size: 0.9rem
    }

    .authorize-container {
        display: flex;
        align-items: center;
        
        gap: 1rem;

        p {
            color:rgba(0, 0, 0, 0.6);
            font-size: 0.9rem
        }

        .authorized {
            color:#739570;
            display: flex;
            align-items: center;
            gap: 0.2rem
        }

        .submit-button {
            margin: 0;
            background-color: #fff;

            color: rgb(60, 64, 67);
            font-weight: 400;
            font-size: 14px;
            
            border: 1px solid #dadce0;
            border-radius: 4px;
            transition: background-color .218s,border-color .218s;

            display: flex;
            align-items: center;
            gap: 8px;
            
            &:hover {
                border-color: #d2e3fc;
            };
        }
      **/
  position: relative;
  padding: 2rem;
  height: 100vh;
  isolation: isolate;

  overflow-y: hidden;
  overflow-x: hidden;

  .authorize-container {
    display: flex;
    align-items: center;

    gap: 1rem;

    p {
      color: rgba(0, 0, 0, 0.6);
      font-size: 0.9rem;
    }

    .authorized {
      color: #739570;
      display: flex;
      align-items: center;
      gap: 0.2rem;
    }

    .submit-button {
      margin: 0;
      background-color: #fff;

      color: rgb(60, 64, 67);
      font-weight: 400;
      font-size: 14px;

      border: 1px solid #dadce0;
      border-radius: 4px;
      transition: background-color 0.218s, border-color 0.218s;

      display: flex;
      align-items: center;
      gap: 8px;

      &:hover {
        border-color: #d2e3fc;
      }
    }
  }

  .submit-button[disabled],
  .submit-button[disabled] + .save-error p {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.guide-bar {
  position: absolute;
  background-color: white;
  width: calc(100% - 6rem);
  height: 4rem;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3rem;
  box-shadow: 0 11px 16px 9px rgba(0, 0, 0, 0.18);
  z-index: 1;

  button {
    margin: 0;
  }

  .guide-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .save-error {
    display: flex;
    align-items: center;
  }

  .save-error {
    opacity: 0.6;
  }

  .save-error p {
    display: flex;
    align-items: center;
    gap: 0.2rem;

    font-weight: 700;
  }

  .error {
    color: red;
    margin: 0;
  }
}

.guide {
  position: absolute;
  width: 50vw;

  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2;

  background-color: #fff;
  box-shadow: 0 11px 16px 9px rgba(0, 0, 0, 0.1);

  transform: translateX(100%);
  transition: transform 0.2s ease-in-out;

  padding: 2rem;
  overflow-y: scroll;

  &.show {
    transform: translateX(0%);
  }

  .close-button {
    background-color: #fff;
    border-radius: 9999px;
    border: none;

    cursor: pointer;

    font-size: 1.5rem;

    position: fixed;
    top: 10px;
    left: calc(32px - 6px);

    display: flex;
    align-items: center;
    gap: 0.3rem;

    &::after {
      content: 'Close';
      font-size: 1rem;
      color: #000;
      transform: scaleX(0);
      transform-origin: left;
      transition: all 0.05s ease;
      margin-top: 0.25rem;
      text-decoration: underline;
    }

    &:hover::after {
      transform: scaleX(1);
    }
  }

  .step-heading {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .step-heading p {
    background-color: #739570;
    color: #fff;
    font-weight: bold;
    font-size: 0.7rem;

    padding: 10px 20px;
    border-radius: 30px;

    width: max-content;
  }

  .guide-steps-list {
    list-style-type: decimal;

    ol {
      list-style-type: lower-alpha;
    }

    ol ol {
      list-style-type: lower-roman;
    }

    .guide-step {
      >span {
        background-color: #739570;
        color: white;
        padding: 3px 9px;
        border-radius: 999px;
      }
    }
  }

  ol {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &.no-number {
      list-style-type: none;
      padding-left: 0;
    }

    li {
      line-height: 30px;
    }

    li a {
      text-decoration: none;
    }

    li a span {
      vertical-align: bottom;
      margin-bottom: 0.25rem;
    }

    code {
      background-color: rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      padding: 0.2em 0.5em;
    }

    .img-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 1rem;
      margin-bottom: 1rem;

      img {
        border-radius: 5px;
        border: 1px solid #dfe1e5;
        width: 100%;
        height: auto;
      }
    }
  }
}

.button-tip {
  background-color: #739570;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  vertical-align: bottom;

  &:hover {
    background-color: #577055;
  }
}
