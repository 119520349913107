div.steps-progress {
  position: relative;
  display: flex;
  align-items: center;

  &.overlay {
    height: 100%;
    top: 0;
    position: absolute;
    overflow-x: hidden;
    white-space: nowrap;

    .line {
      border-bottom: 2px dashed #739570;
      min-width: 10rem;
    }
  }

  .step {
    margin: 0 7px;
    display: inline-block;
    font-weight: 700;
    font-size: 13px;
    cursor: pointer;
  }
  .line {
    display: inline-block;
    height: 0;
    width: 10rem;
    border-bottom: 2px dashed #dddcd7;
  }
  .step-title {
    text-align: center;
    margin-left: calc((3.5rem - 15px) * -1);
    width: 7rem;
    position: absolute;

    &.gray {
      color: #9b9b9b;
    }
  }
}
.material-icons.steps-progress {
  font-size: 30px;
}

.green {
  color: #739570;
}

.gray {
  color: #dddcd7;
}
