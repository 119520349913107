.log-head {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  place-items: center;
  align-items: center;

  position: relative;

  background-color: #f4f3ed;
  border-radius: 3px;
  border-bottom: 1px solid #d3d2cc;

  :nth-child(3) {
    grid-column: span 2;
  }

  font-size: 0.9rem;

  cursor: pointer;

  &:hover {
    background-color: #dddcd7a9;
  }

  &.expanded {
    background-color: #dddcd7a9;
  }

  .total-runtime {
    color: #739570;
  }

  .arrow {
    position: absolute;
    left: 10px;
    transition: all 0.2s linear;

    &.rotate {
      transform: rotateZ(90deg);
    }

    &:hover {
      background-color: #d0cfcb;
      border-radius: 9999px;
    }
  }
}

.log-body {
  // margin-left:3rem;
  margin-top: 0.2rem;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  .log-step {
    .log-head {
      // font-size: 0.8rem;
      // justify-content: space-evenly;
      border-bottom: 1px solid #d3d2cc;

      :nth-child(2) {
        grid-column: span 2;
      }

      :nth-child(3) {
        grid-column: span 1;
      }

      &:hover {
        background-color: #f0efeaa9;
      }

      p {
        margin: 0.5em;
      }

      // .step-container {
      //     text-align: left;
      //     width: 150px;
      // }

      // time {
      //     padding-right: 3rem;
      // }
    }
  }
}

// table
.log-table {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  // background-color: #f4f3ed;
  border-radius: 3px;

  padding: 0.6rem;
  margin: 3rem;
  margin-top: 0;
}

.table-header {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  place-items: center;

  :nth-child(2) {
    grid-column: span 2;
  }

  p {
    border-radius: 3px;
    padding: 7px 15px;
    margin: 0;

    text-transform: capitalize;
    font-weight: 600;
  }
}
