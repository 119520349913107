div.invite-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  div.alert.alert-danger {
    margin-top: 20px;
    margin-bottom: auto;
    width: 90%;
  }
  div.actions {
    margin-top: 40px;
  }
}
