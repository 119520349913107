.switch-container {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1em;
}

.bill-choice.active {
  color: #739570;
}

.bill-choice {
  font-weight: 600;
  font-size: 0.8em;
  color: #1b1b1b;
  text-align: center;

  transition: all 300ms ease-out;
}

/*Switch*/
#plan-switch {
  height: 0;
  width: 0;
  visibility: hidden;
}

#plan-switch + label {
  cursor: pointer;
  text-indent: -9999px;
  width: 60px;
  height: 30px;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
}

#plan-switch + label:after {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

#plan-switch:checked + label {
  background: #739570;
}

#plan-switch:checked + label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

#plan-switch + label:active:after {
  width: 13px;
}
