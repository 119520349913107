.manual-expenses {
  .head {
    display: flex;

    .submit-button {
      margin-left: auto;
      width: 10rem;

      span.enlarge {
        margin-bottom: 4px;
        margin-right: 10px;
        font-weight: 400;
      }
    }
  }

  .pop-up {
    height: 18rem;
  }

  #expenses-table {
    img {
      max-width: 35px;
    }

    .table-title {
      background-color: inherit;
      min-height: 0;
      padding-bottom: 0;
      padding-top: 0;
      height: 1rem;
      text-align: left;
    }

    div.column {
      height: auto !important;
      min-height: auto !important;
      padding: 10px 30px !important;
      margin-top: 2px !important;
      border-radius: 0 !important;

      span.name {
        font-weight: 400 !important;
      }
    }
  }

  .manual-expenses-form.form-loading {
    pointer-events: none;
    .form-container {
      position: relative;
      .form-body {
        opacity: 0.2;
      }
      .loading-shade {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9998;
        opacity: 0.1;
        background-color: #ddd;
      }
      .loading-indicator {
        opacity: 1;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 50px;
        z-index: 9999;
        margin-left: -25px;
        margin-top: -25px;
        opacity: 1;
      }
    }
    .grid {
    }
  }

  .manual-expenses-form {
    .loading-indicator {
      display: none;
    }
    .associated-service .drop-down-input-field-with-image {
      margin-top: 27px;
    }
    .form-container {
    }
    .section {
      margin-top: 20px;

      .section-header {
        margin-bottom: 20px;

        .section-title {
          font-weight: bold;
          display: block;
        }

        .section-subtitle {
          display: block;
        }
      }
    }
    .advanced-title {
      .material-icons-outlined {
        vertical-align: middle;
      }
    }
    .actions {
      margin-top: 40px;
      .saved-icon {
        margin-top: 10px;
      }
      .saving-error {
        margin-top: 10px;
      }
    }
  }

  .collapse-open {
    margin-top: 20px;
    display: block;
  }

  .collapse-closed {
    display: none;
  }

  .link-button {
    border: 0;
    background-color: inherit;
    font-size: 16px;
    color: #739570;
  }
}
