.full-screen {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

div.page-column {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-weight: 400;
}

div.page-row {
  height: calc(100% - 38px);
  display: flex;
  flex-direction: row;
  font-weight: 400;
  flex-grow: 1;
  background-color: rgb(250, 250, 250);
}

.container-column {
  display: flex;
  flex-direction: column;
}

.container-row {
  display: flex;
  flex-direction: row;
}

.container-center {
  align-items: center;
  justify-content: center;
}

.side-page {
  flex-grow: 1;
  flex-basis: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  > .container-row {
    display: flex;
    flex-direction: row;
    height: calc(100% - 43px);
  }

  &:has(.dashboard) {
    background-color: white;
  }
}

.flex-grow {
  flex-grow: 1;
  flex-basis: 0;
}

.container-grow {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.width-100 {
  width: 100%;
}

.height-100 {
  width: 100%;
}

nav.page-navigation-menu {
  ul {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    padding: 0;

    li {
      cursor: pointer;
      margin-left: 3rem;

      &:first-child {
        margin-left: 0;
      }

      &.selected {
        color: #739570;
        font-weight: 700;

        &::after {
          content: '';
          height: 3px;
          background: #739570;
          border-radius: 10px;
          display: block;
        }
      }
    }
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 0 20px;
}

.grid > div {
  grid-column: span 1;
}

.grid-rows {
  display: grid;
  grid-template-rows: repeat(12, 1fr);
}

.grid > .grid-column-1 {
  grid-column: span 1;
}
.grid > .grid-column-2 {
  grid-column: span 2;
}
.grid > .grid-column-3 {
  grid-column: span 3;
}
.grid > .grid-column-4 {
  grid-column: span 4;
}
.grid > .grid-column-6 {
  grid-column: span 6;
}
.grid > .grid-column-5 {
  grid-column: span 5;
}
.grid > .grid-column-7 {
  grid-column: span 7;
}
.grid > .grid-column-8 {
  grid-column: span 8;
}
.grid > .grid-column-9 {
  grid-column: span 9;
}
.grid > .grid-column-10 {
  grid-column: span 10;
}
.grid > .grid-column-11 {
  grid-column: span 11;
}
.grid > .grid-column-12 {
  grid-column: span 12;
}

@media (min-width: 992px) {
  .grid > div.g-col-lg-1 {
    grid-column: span 1;
  }
  .grid > div.g-col-lg-2 {
    grid-column: span 2;
  }
  .grid > div.g-col-lg-3 {
    grid-column: span 3;
  }
  .grid > div.g-col-lg-4 {
    grid-column: span 4;
  }
  .grid > div.g-col-lg-6 {
    grid-column: span 6;
  }
  .grid > div.g-col-lg-5 {
    grid-column: span 5;
  }
  .grid > div.g-col-lg-7 {
    grid-column: span 7;
  }
  .grid > div.g-col-lg-8 {
    grid-column: span 8;
  }
  .grid > div.g-col-lg-9 {
    grid-column: span 9;
  }
  .grid > div.g-col-lg-10 {
    grid-column: span 10;
  }
  .grid > div.g-col-lg-11 {
    grid-column: span 11;
  }
  .grid > div.g-col-lg-12 {
    grid-column: span 12;
  }
}

@media (min-width: 1200px) {
  .grid > div.g-col-xl-1 {
    grid-column: span 1;
  }
  .grid > div.g-col-xl-2 {
    grid-column: span 2;
  }
  .grid > div.g-col-xl-3 {
    grid-column: span 3;
  }
  .grid > div.g-col-xl-4 {
    grid-column: span 4;
  }
  .grid > div.g-col-xl-6 {
    grid-column: span 6;
  }
  .grid > div.g-col-xl-5 {
    grid-column: span 5;
  }
  .grid > div.g-col-xl-7 {
    grid-column: span 7;
  }
  .grid > div.g-col-xl-8 {
    grid-column: span 8;
  }
  .grid > div.g-col-xl-9 {
    grid-column: span 9;
  }
  .grid > div.g-col-xl-10 {
    grid-column: span 10;
  }
  .grid > div.g-col-xl-11 {
    grid-column: span 11;
  }
  .grid > div.g-col-xl-12 {
    grid-column: span 12;
  }
}

@media (min-width: 1400px) {
  .grid > div.g-col-xxl-1 {
    grid-column: span 1;
  }
  .grid > div.g-col-xxl-2 {
    grid-column: span 2;
  }
  .grid > div.g-col-xxl-3 {
    grid-column: span 3;
  }
  .grid > div.g-col-xxl-4 {
    grid-column: span 4;
  }
  .grid > div.g-col-xxl-6 {
    grid-column: span 6;
  }
  .grid > div.g-col-xxl-5 {
    grid-column: span 5;
  }
  .grid > div.g-col-xxl-7 {
    grid-column: span 7;
  }
  .grid > div.g-col-xxl-8 {
    grid-column: span 8;
  }
  .grid > div.g-col-xxl-9 {
    grid-column: span 9;
  }
  .grid > div.g-col-xxl-10 {
    grid-column: span 10;
  }
  .grid > div.g-col-xxl-11 {
    grid-column: span 11;
  }
  .grid > div.g-col-xxl-12 {
    grid-column: span 12;
  }
}

.grid-row-1 {
  grid-row: span 1;
}
.grid-row-5 {
  grid-row: span 5;
}
.grid-row-6 {
  grid-row: span 6;
}

.side-page::-webkit-scrollbar {
  display: none;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}
*::-webkit-scrollbar {
  background-color: transparent;
  width: 15px;
}
*::-webkit-scrollbar-thumb {
  border-radius: 20px;
  border: 4px solid transparent;
  background-color: rgba(0,0,0,0.2);
  background-clip: content-box;

  &:hover {
    background-color: rgba(0,0,0,0.4);
  }
}