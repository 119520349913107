header {
  display: grid;
  grid-template-columns: 20% auto 20%;
  height: 5rem;
  padding: 0 2rem;

  div.logo {
    grid-column: 1;
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 28px;
    font-weight: 700;
    padding-top: 2rem;

    img {
      width: 80px;
    }
  }
}
