.notification-page {
  // height: 100%;
  padding: 15px 25px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;

  .notification-preview {
    max-height: 150px;
    height: max-content;
    width: calc(100% - 30px);
    max-width: calc(100% - 20px);
    // background-color: #d9d9d9;
    background-color: #d9d9d938;
    box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 10px 15px;

    .notification-preview__header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        margin: 0;
      }

      .notification-preview__title {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        font-weight: 700;
        font-size: 18px;
        align-self: self-start;

        &::before {
          content: 'Title';
          color: #282727a8;
          font-size: 10px;
          font-weight: normal;
        }
      }
      .notification-preview__date {
        color: #739570;
        font-size: 12px;
        text-align: end;
        align-self: self-start;
      }
    }
    &::webkit-scrollbar {
      display: block;
    }
    .notification-preview__body {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      overflow-y: scroll;

      &::before {
        content: 'Body';
        color: #282727a8;
        font-size: 10px;
      }
    }
  }

  .notification-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;
    width: calc(100% - 20px);
    height: 60vh;
    background-color: #d9d9d9;
    box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.05);

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    .notification-filter-container {
      // background-color: blue;
      display: flex;
      justify-content: flex-start;
      text-transform: capitalize;

      padding: 12px 4px;

      .notification-filter-list {
        display: flex;
        justify-content: center;
        gap: 20px;

        li.notification-filter {
          &[data-selected='true'] {
            border-bottom: 2px solid #000;
            padding-bottom: 1px;
            font-weight: bold;
          }

          &:hover {
            cursor: pointer;

            &:not([data-selected='true']) {
              border-bottom: 1px solid #000;
              padding-bottom: 1px;
            }
          }
        }
      }
    }

    .notification-container {
      background-color: #fff;
      border-radius: 4px;
      box-shadow: 0px 4px 10px rgba(#000, 0.25);
      padding: 20px;
      overflow-y: auto;
      scrollbar-gutter: auto;
      flex-grow: 1;
      min-height: 300px;

      .notification-list {
        display: flex;
        flex-direction: column;
        gap: 0.7rem;

        .notification {
          position: relative;
          &:is([data-unread='true']) {
            & > .item {
              background-color: #edf2ed;
              border-color: rgba(0, 0, 0, 0.03);
            }

            &::before {
              content: '';
              display: block;
              position: absolute;
              transform: translate(-30%, -30%);
              background-color: #739570b8;
              width: 15px;
              aspect-ratio: 1/1;
              border-radius: 999px;
            }
          }
          .item {
            border: 1px solid rgba(0, 0, 0, 0.07);
            height: 70px;
            border-radius: 4px;
            padding: 0 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            transition: border 0.1s linear;

            &:hover,
            &[data-status='2']:hover,
            &[data-status='3']:hover {
              cursor: pointer;
              border-color: rgba(0, 0, 0, 0.4);
              color: #000;
            }

            &[data-status='2'],
            &[data-status='3'] {
              color: #00000088;
              background-color: #fff;
            }

            button.action-btn {
              border: none;
              background: none;
              padding: 4px 8px;
              aspect-ratio: 1;
              display: flex;
              align-items: center;
              border-radius: 999px;
              cursor: pointer;

              &:hover {
                background-color: #73957072;
              }
            }

            .notification-item__header {
              display: flex;
              flex-direction: column;
              gap: 0.1rem;

              & > p {
                margin: 0;
              }

              .notification-item__title {
              }

              .notification-item__info {
                display: flex;
                align-items: center;

                span.material-icons-outlined,
                span.material-icons {
                  scale: 0.6;
                  border-radius: 999px;

                  &[data-status='0'] {
                    color: rgba(114, 114, 224, 0.664);
                  }
                  &[data-status='1'] {
                    color: rgba(236, 90, 90, 0.716);
                  }
                  &[data-status='2'] {
                    color: rgb(178, 178, 3);
                  }
                  &[data-status='3'] {
                    color: #739570;
                  }
                }

                .notification-item__date {
                  font-size: 12px;
                  color: #739570;
                  margin: 0;
                  // needed for circle to align properly
                  display: flex;
                  align-items: center;
                  gap: 0.2rem;

                  &::before {
                    content: '';
                    display: inline-block;
                    height: 3px;
                    width: 3px;
                    background-color: #739570;
                    border-radius: 999px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 500px) {
    padding: 15px 0;
    h1 {
      text-align: center;
      width: 100%;
    }
  }
}
