.options-container {
  z-index: 9999;

  &.disabled {
    opacity: 0.3;
  }

  .options-controls {
    cursor: default;
  }
  &:not(.disabled) .options-controls {
    position: relative;
    cursor: pointer;

    .controls-menu {
      position: absolute;
      background-color: white;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      top: 25px;
      right: 10px;
      border-radius: 3px;
      border: 1px solid #c2c1bc7d;
      z-index: 9999;
      width: 150px;
      box-shadow: 1px 1px 4px 1px #80808033;

      .option {
        display: flex;
        align-items: center;
        font-weight: normal;
        width: calc(100% - 20px);
        padding: 7px 10px;
        border-bottom: 1px solid #e6e6e5;

        &:not(:has(> .delete)):hover {
          background-color: #c2c1bc3d;
        }

        &:has(> .delete):hover {
          color: red;
        }
      }
    }
  }
}
