.progress-bar-container {
    display: flex;
    align-items: center;
    gap:1rem;

    &.right {
        flex-direction: row-reverse;
    }

    &.center {
        position: relative;
        .percent-value {
            position: absolute;
            transform: translate(-50%, -50%);
            top: -50%;
            left: 50%;
            color: white;
            background-color: #739570;
            border-radius: 3px;
            padding: 1px 5px;
        }
    }

    .upload-progress {
        accent-color: #739570;
        width: 300px; 
    }
}
