.sources {
  flex-grow: 1;
  overflow-y: auto;
  padding: 4rem 3rem;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  position: relative;

  div.no-sources {
    display: flex;
    flex-grow: 1;

    div.box {
      margin: auto;
      padding: 10rem 5rem;
      width: 20rem;
      height: 15rem;
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: center;

      h1 {
        text-align: center;
      }

      div {
        text-align: center;
      }

      button {
        margin-top: 3rem;
        width: 10rem;

        display: flex;
        align-items: center;
        gap: 5px;

        .enlarge {
          padding-bottom: 2px;
        }
      }
    }
  }

  .head {
    display: flex;

    button {
      margin-left: auto;
      width: 10rem;

      span.enlarge {
        margin-bottom: 4px;
        margin-right: 10px;
        font-weight: 400;
      }
    }
  }

  h2 {
    margin-bottom: 4px;
  }

  nav {
    ul {
      display: flex;
      flex-direction: row;
      list-style-type: none;
      padding: 0;

      li {
        margin-left: 3rem;
        cursor: pointer;

        &:first-child {
          margin-left: 0;
        }

        &.selected {
          color: #739570;
          font-weight: 700;

          &::after {
            content: '';
            height: 3px;
            background: #739570;
            border-radius: 10px;
            display: block;
          }
        }
      }
    }
  }

  div.content {
    display: flex;
    flex-direction: column;
  }
}

div.integrations-category {
  padding: 1rem 0.9rem;
  border: 1px solid #dddcd7;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;

  &:last-child {
    margin-bottom: 6rem;
  }

  span.title {
    margin-left: 6px;
    font-weight: 700;
  }

  span.subtitle {
    margin-left: 6px;
    margin-top: 3px;
    color: #9b9b9b;
    font-size: 13px;
  }

  table {
    margin-top: 1rem;
    width: 100% !important; /* Alex: no idea why this is needed */
    thead {
      color: #9b9b9b;
      font-size: 12px;
      display: flex;
      tr {
        margin-left: 20px;
        margin-right: 20px;
        flex-grow: 1;

        th {
          padding: 0;
          font-weight: 400;
          text-align: left;

          &.actions {
            user-select: none;
            color: black;
          }
        }
      }
    }

    tbody {
      margin-bottom: 1rem;
      display: flex;
      flex-direction: column;
      margin-top: 6px;
      border-radius: 4px;
      background-color: white;

      tr {
        padding-left: 20px;
        padding-right: 20px;
        height: 5rem;
        flex-grow: 1;

        &.accounts-header {
          border-top: 1px solid #f7f6f1;
          height: 2rem;

          th {
            color: #9b9b9b;
            display: flex;
            align-items: center;
            padding: 0;
            font-weight: 400;
            text-align: left;
            margin-left: 10px;
            font-size: 12px;

            &.actions {
              color: black;
            }
          }
        }

        .actions {
          overflow: visible;
          color: black;
          cursor: default;

          div.actions-drop-down {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            position: relative;
            height: 0.8rem;

            span.material-icons {
              margin-left: 2px;
              margin-top: 2px;
              font-size: 10px;
              font-weight: 700;
            }
            div.delete-confirmation {
              margin: 0;
              list-style-type: none;
              position: absolute;
              top: calc(14px + 0.8rem);
              left: -1px;
              height: 2rem;
              border: 1px solid #dddcd7;
              border-radius: 4px;
              overflow: hidden;
              background-color: white;
              display: flex;
              flex-direction: column;
              padding: 3px 10px;
              font-weight: 400;
              align-items: center;

              span.message {
                text-align: center;
                flex-grow: 1;
                color: #9b9b9b;
                white-space: nowrap;
              }

              span.delete {
                text-align: center;
                flex-grow: 1;
                color: #dc3545;
                cursor: pointer;
              }
            }

            ul.actions-drop-down-options {
              padding: 6px;
              margin: 0;
              list-style-type: none;
              position: absolute;
              top: calc(14px + 0.8rem);
              right: -1px;
              border: 1px solid #dddcd7;
              border-radius: 4px;
              overflow: hidden;
              background-color: white;
              z-index: 2;
              width: max-content;

              li {
                font-size: 12px;
                font-weight: 400;
                padding: 5px;
                display: flex;
                align-items: center;
                justify-content: left;
                height: 1.3rem;
                background-color: white;
                cursor: pointer;

                border-bottom: 1px solid #f7f6f1;

                &:last-child {
                  border-bottom: none;
                }

                &.reconnect {
                  span.material-icons-outlined {
                    font-size: 15px;
                    margin-right: 3px;
                  }
                }

                &.delete {
                  color: #dc3545;
                  opacity: 0.85;

                  span.material-icons-outlined {
                    font-size: 15px;
                    margin-right: 3px;
                  }
                }

                &:hover {
                  opacity: 1;
                }
              }
            }
          }
        }

        &.service {
          cursor: pointer;
        }

        &.account {
          border-top: 1px solid #f7f6f1;

          td {
            &.integration {
              .information {
                font-size: 12px;
              }

              .uuid {
                margin-top: 5px;
                color: #9b9b9b;
                font-size: 10px;
              }
            }
          }
        }

        td {
          padding: 0;
          flex-grow: 1;
          flex-basis: 0;
          font-weight: 400;
          flex-grow: 2;

          &.integration {
            display: flex;
            align-items: center;
            flex-grow: 3;
            margin-left: 10px;

            img {
              margin-right: 25px;
              max-width: 35px;
            }

            .information {
              display: flex;
              flex-direction: column;
              .name {
                font-weight: 700;
                font-size: 14px;
              }

              .connections {
                margin-top: 4px;
                color: #9b9b9b;
                font-size: 12px;
              }
            }
          }

          &.status {
            align-items: center;

            div.status-circle {
              margin: 0;
              height: 10px;
              width: 10px;
              border-radius: 50%;
              display: inline-block;

              &.failed {
                background-color: #d96249;
              }

              &.pending {
                background-color: #f7d165;
              }

              &.running {
                display: none;
              }
            }

            div.spinner-grow {
              height: 10px;
              width: 10px;
              color: #f7d165;
            }

            span.status-info {
              margin-left: 5px;
              padding-right: 10px;
              font-size: 10px;
              grid-column: span 11;
            }
          }

          &.date, &.uuid {
            display: flex;
            flex-direction: column;
            font-size: 10px;
            justify-content: center;

            span.created {
              margin-top: 2px;
            }
          }

          &.actions {
            user-select: none;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-grow: 0.7;
            color: black;
            width: 61.67px;

            span.material-icons {
              font-size: 30px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .sources div.no-sources div.box {
    padding: 0%;
    background-color: transparent;
  }
}