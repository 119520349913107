.container {
  padding: 32px 48px;
  margin-bottom: 65px;
  margin-bottom: 65px;

  h3 {
    margin: 0;
  }

  .main-header {
    display: flex;
    align-items: center;
    gap: 1rem;

    > button {
      cursor: pointer;
      border: none;
      background-color: transparent;

      span {
        margin-top: 5px;
      }
    }
  }

  .custom-columns {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    .header {
      .table-name > .input-field {
        width: 350px;
      }
    }
    .main {
      .column-header {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        gap: 40px;

        padding: 12px 23px 0 23px;

        div {
          width: 250px;
          display: flex;
          justify-content: flex-start;

          > p {
            color: #9b9b9b;
          }

          &:nth-child(4) {
            justify-content: flex-end;
          }

          &:last-child {
            width: 24px;
          }
        }
      }

      .row {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        gap: 40px;
        border: 1px solid #dddcd7;
        border-top: none;
        background-color: white;
        border-radius: 4px;
        padding: 12px 23px;

        position: relative;

        .hidden {
          display: none;
        }

        span.delete {
          &:hover {
            color: red;
            cursor: pointer;
          }
        }

        .field {
          width: 250px;
          display: flex;
          justify-content: flex-start;

          .drop-down-input-field {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 30px;
            margin: 0;

            .value {
              padding: 0;
              display: flex;
              align-items: center;
            }

            ul.drop-down-options {
              top: 31px;
            }

            span.arrow {
              margin: 0;
            }
          }

          .input-field:not([type='checkbox']) {
            background-color: white;
            width: 100%;
            height: 30px;
            border: 1px solid #dddcd7;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            padding: 0 10px 0 10px;
            outline: none;
            opacity: .6;
          }
        }

        .delete-row.disabled .delete {
          opacity: 0.3;
        }

        .delete-row:not(.disabled) {
          .delete:hover {
            cursor: pointer;
            color: red;
          }
        }

        .field:has(input[type='checkbox']) {
          justify-content: flex-end;
        }
      }

      .row:not(:first-child):not(:last-child) {
        border-radius: 0;
      }

      .row:nth-child(2n) {
        background-color: #f7f7f7;
      }

      > div:nth-of-type(2) {
        border-top: 1px solid #dddcd7;
      }

      .submit-button {
        margin-top: 32px;
      }

      .btn-container {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }

    .api {
      .api-header {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        cursor: pointer;

        span.rotate {
          rotate: -90deg;
        }
      }

      .api-fields {
        .submit-button {
          margin-top: 32px;
        }
      }
    }
  }
}

.container + .save-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  padding: 0 48px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  border-top: 1px solid #dddcd7;
  z-index: 1;

  .submit-button {
    margin: 0;
  }

  .error-message {
    color: #e06666;
  }
  
}

//upload file modal
.upload-file {
  padding: 0px 48px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  overflow: scroll;

  .upload-file__body {
    display: flex;
    align-items: center;
    gap: 16px;
    
    .upload-file__body__upload__file-name {
      color: #739570;
    }
  }

  .upload-file__column-schema {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 1rem;

    .upload-file__header__column {
      background-color: #cecccc68;
      padding: 3px 5px;
      border-radius: 3px;
      text-overflow: ellipsis;
      overflow: auto;
      white-space: nowrap;
      cursor: pointer;

      &:hover {
        max-width: 100%;
        overflow: visible;
        text-overflow: unset;
        background-color: #cecccc98;
      }

      &.missing {
        background-color: #e06666;
        color: white;
      }

      &.found {
        background-color: #739570;
        color: white
      }
    }
  }
  
  .error {
    color: #e06666
  }

  .upload-file__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    h4 {
      margin: 0;
    }

    .uploaded-file {
      font-size: 1.3rem;
      display: flex;
      align-items: "center";
      background-color: #dddcd79e;
      padding: 4px 7px;
      border-radius: 3px;


      .clear-btn {
        appearance: none;
        border: none;
        background-color: transparent;
        scale: 1.7;  
      }
      &:hover {
        background-color: #dddcd743;
        > * {
        cursor: pointer;
        color: #e06666;
        }
      }

    }

  }

  .upload-file__footer {
    display: flex;
    flex-direction: column;
    gap: .3rem;
    justify-content: space-between;
    align-items: center;
    width: 100%;
     
    .upload-file__body__upload__error {
      font-size: 1rem;
      color: #e06666;
    }

  }
}