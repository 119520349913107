.upload-zone {
    p {
      margin: 0;
      text-align: center;
    }
    // dropzone
    #dropzone {
        position: relative;
        border: 3px dashed #ccc;
        border-radius: 20px;
        color: #ccc;
        font: bold 24px/200px arial;
        font-size: 18px;
        margin: 30px auto 10px auto;
        text-align: center;
        max-width: 100%;
        max-height: 100px;
    
        &:hover {
        border: 3px solid #739570;
        color: #739570;
        }
    
        &.dropped {
        background: #222;
        border: 10px solid #444;
        }
    
        .dropzone__title {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        height: 100px;
        }
    
        [type='file'] {
        cursor: pointer;
        position: absolute;
        opacity: 0;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        }
    }
}