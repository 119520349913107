div.thank-you-page {
  margin: 75px 4rem;
  margin-bottom: 30px;
}

div.thank-you-box {
  background-color: white;
  width: 32rem;
  height: 36rem;
  border-radius: 12px;
  padding: 3rem;
  padding-top: 2rem;
  margin-bottom: 2rem;
}
