.tooltip {
  position: relative;

  &:is(.top) {
    &:hover::before {
      top: -26px;
    }
    &::before {
      top: 0;
    }
  }

  &:is(.bottom) {
    &:hover::before {
      bottom: -26px;
    }
    &::before {
      bottom: 0;
    }
  }

  &:hover::before {
    scale: 1;
  }

  &::before {
    content: attr(data-tooltip);
    transition: 0.1s linear;
    transition-property: scale, top;
    background-color: #757575;
    color: #fff;
    padding: 4px 10px;
    scale: 0;
    position: absolute;
    left: 50%;
    border-radius: 3px;
    transform: translateX(-50%);
    transform-origin: left;
  }
}
