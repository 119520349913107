div.cluster-settings {
  width: 40vw;
  flex-grow: 1;
  flex-basis: 0;
  overflow-y: auto;

  &.preview-open {
    flex-grow: 10;
  }

  div.expand-preview {
    width: 25px;
    border: 2px solid #dddcd7;
    border-bottom: 0;
    border-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: calc(100% - 110px);
    right: 0;
    cursor: pointer;
    background: white;

    &:hover {
      border-color: #c9c8c3;
      cursor: pointer;

      div.expand-square {
        border-color: #c9c8c3;
        left: calc(-2.1rem - 2px);
        width: 2.1rem;

        span.material-icons-outlined {
          opacity: 0.7;
        }
      }
    }
  }
}

div.expand-square {
  position: absolute;
  top: -2px;
  left: calc(-2rem - 2px);
  width: 2rem;
  height: 2rem;
  background-color: white;
  background-color: white;
  border: 2px solid #dddcd7;
  border-right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  &:hover {
    cursor: pointer;
    left: calc(-2.1rem - 2px);
    width: 2.1rem;
    span.material-icons-outlined {
      opacity: 0.7;
    }
  }

  span.material-icons-outlined {
    opacity: 0.5;
    font-size: 20px;

    &.rotated {
      transform: rotate(180deg);
    }
  }
}

div.show-preview {
  width: 55vw;
  background-color: white;
  border: 2px solid #dddcd7;
  border-bottom: 0;
  border-right: 0;
  overflow: visible;
  position: relative;
  display: flex;
  flex-direction: column;

  tab-size: 4;
  font-size: 15px;

  div.helper-div {
    height: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }

  div.preview {
    flex-grow: 1;
    margin-top: 15px;
    row-gap: 10px;
    min-height: 35rem;
    overflow-y: scroll;
    margin: 0 1rem;
    margin-bottom: 4rem;
    padding: 15px 0;
    display: flex;
    flex-direction: column;

    h2 {
      position: relative;
      display: flex;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;

      button.refresh {
        position: absolute;
        right: 0;
        font-size: 15px;
        width: 7rem;
        height: 2.1rem;
        cursor: pointer;
        margin-top: 2px;
        opacity: 0.9;

        &.deactivated {
          opacity: 0.4;

          &:hover {
            opacity: 0.4;
            cursor: default;
          }
        }

        &:hover {
          opacity: 1;
        }

        span.material-icons-outlined.arrow {
          font-size: 13px;
          font-size: 17px;
          font-weight: bold;
          margin-right: 4px;
        }
      }
    }

    .drop-down-input-field {
      width: 70%;
      height: 2rem;
      min-height: 2rem;

      div.value {
        padding-top: 0.5rem;
        font-size: 13px;
      }

      input.value {
        padding-top: 0.2rem;
      }

      span.material-icons.arrow {
        margin-top: 6px;
      }

      ul.drop-down-options {
        top: calc(2rem + 1px);
        li {
          font-size: 12px;
        }
      }
    }
  }

  div.sql-container {
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    flex-direction: column;

    div.sql-title {
      cursor: pointer;
      margin-bottom: 5px;
      .sql-title-text {
        vertical-align: middle;
      }

      .material-icons-outlined {
        vertical-align: middle;
      }
    }

    div.preview-sql {
      flex-grow: 1;
      flex-basis: 0;
      display: flex;
      flex-direction: column;
      border: 1px solid #dddcd7;
      border-radius: 4px;
      margin-bottom: 2rem;
      font-size: 12px;

      code {
        flex-grow: 1;
        display: block;
        white-space: pre;
        padding: 20px;
        line-height: 1.5;
        width: max-content;

        .sql-hl-keyword {
          color: #488a42;
        }
        .sql-hl-string {
          color: #96831a;
        }
      }
    }
  }

  div.preview-columns {
    border: 1px solid #dddcd7;
    border-radius: 4px;
    display: flex;

    flex-grow: 1;
    flex-basis: 0;
    overflow: auto;

    table {
      border-collapse: collapse;
      width: 100%;
      display: flex;
      flex-direction: column;
      height: max-content;

      tbody {
        flex-grow: 1;
      }

      thead {
        position: sticky;
        top: 0;
      }

      thead tbody {
        width: 100%;
        display: flex;
        flex-direction: column;
      }

      tr {
        display: flex;

        th {
          font-size: 11px;
          display: flex;
          flex-grow: 1;
          flex-basis: 0;
          align-items: center;
          justify-content: center;
          padding: 0 10px;
          min-width: 70px;
          height: 35px;
          background-color: #739570;
          border-right: 2px solid white;
          color: white;
          cursor: default;
          position: relative;
          padding-right: 22px;

          &.larger {
            min-width: 220px;
          }

          span.name {
            height: 13px;
          }

          span.material-icons-outlined.arrow {
            position: absolute;
            font-size: 20px;
            top: 9px;
            right: 4px;

            &.transparent {
              opacity: 0.6;
            }
          }

          &:last-child {
            border-right: none;
          }
        }

        &:nth-child(even) td {
          background-color: #f2f1f2;
        }

        td {
          flex-grow: 1;
          flex-basis: 0;
          font-size: 11px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 10px;
          height: 25px;
          min-width: 82px;
          border-right: 2px solid white;
          cursor: default;
          padding-right: 10px;

          &.larger {
            min-width: 232px;
          }

          span {
            height: 13px;
          }

          &:last-child {
            border-right: none;
          }

          &.align-right {
            justify-content: flex-end;
          }
          &.align-left {
            justify-content: flex-start;
          }
        }
      }
    }
  }
}

div.clusters-campaigns-column {
  padding: 4rem 3rem;
  padding-top: 2rem;

  .competitors-warning {
    margin-top: 20px;
    .material-icons-outlined {
      font-size: 1em;
      color: #999;
      display: inline-flex;
      vertical-align: bottom;
      padding-right: 4px;
    }
  }

  span.material-icons-outlined.back-arrow {
    cursor: pointer;
    font-size: 30px;
    margin-bottom: 20px;
  }

  button.link.add-ruleset {
    grid-column-start: 3;
    margin: 20px 0;
    padding-left: 8px;
    white-space: nowrap;
    display: flex;
    font-size: 13px;

    .enlarge {
      margin-top: -9px;
    }
  }

  div.header {
    margin-bottom: 2rem;
  }

  div.column-name {
    grid-column: span 5;

    input {
      font-size: 15px;
    }
  }

  .draggable-ruleset {
    margin-top: 3rem;
  }

  .rule-set {
    border: 1px solid #dddcd7;
    border-radius: 4px;
    background-color: white;
    padding: 1rem;
    position: relative;

    div.drag-handle-container {
      width: 15px;
      span.material-icons-outlined.drag-handle {
        font-size: 26px;
        opacity: 0.4;
        display: none;

        &.visible {
          display: initial;
        }
      }
    }

    button.link.add-rule {
      grid-column-start: 3;
      margin-top: 5px;
      margin-bottom: 10px;
      padding-left: 8px;
      white-space: nowrap;
      display: flex;

      .enlarge {
        margin-top: -8px;
      }
    }

    h2.when-then {
      grid-column: span 2;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      cursor: default;

      &.collapsed {
        grid-column: span 1;
      }

      span.material-icons-outlined.arrow {
        font-size: 28px;
      }
    }

    div.operator {
      padding: 0 12px;
    }

    div.then-value {
      grid-column: span 7;
    }

    div.helper-grid {
      grid-column: span 10;
      column-gap: 12px;
    }

    div.rule {
      column-gap: 12px;

      div.fix-overflow {
        width: 100%;
      }

      div.bin {
        display: flex;
        user-select: none;

        span.material-icons-outlined {
          cursor: pointer;
          opacity: 0.4;

          &:hover {
            opacity: 1;
          }
        }
      }
    }

    span.options.material-icons-outlined {
      cursor: pointer;
      font-size: 28px;
      width: 20px;
      display: flex;
      justify-content: center;
      line-height: 20px;
      position: absolute;
      right: -10px;
      top: -10px;
      background-color: white;
      border-radius: 50%;
      color: #82807f;
      user-select: none;

      &:hover,
      &.highlighted {
        color: #424242;
      }
    }

    ul.options {
      position: absolute;
      background-color: white;
      border-radius: 3px;
      padding: 0;
      top: -78px;
      right: 1px;
      border: 1px solid #dddcd7;
      list-style-type: none;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 150px;

      li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        padding: 6px 10px;
        width: calc(100% - 20px);

        &:not(:last-of-type) {
          border-bottom: 1px solid #dddcd7ef;

          &:hover {
            background-color: #f3f3f3;
          }
        }

        &.delete {
          span.material-icons-outlined {
            font-size: 19px;
            margin-right: 5px;
            user-select: none;
          }

          &:hover {
            color: #dc3545;
          }
        }

        &.copy {
          span.material-icons-outlined {
            font-size: 19px;
            margin-right: 5px;
            user-select: none;
          }

          color: #424242;
        }
      }
    }
  }
}

div.save-bottom-bar {
  z-index: 1;
  height: 4rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: end;
  padding: 0 2rem;

  margin-top: 4rem;

  background-color: #fff;
  border-top: 2px solid #dddcd7;

  div.spinner-border {
    width: 1rem;
    height: 1rem;
    font-size: 12px;
  }

  button.submit-button {
    margin: 0;

    &.deactivated {
      opacity: 0.4;

      &:hover {
        opacity: 0.4;
        cursor: default;
      }
    }
  }

  span.material-icons-outlined.saved-icon {
    position: absolute;
    left: -30px;
    font-size: 20px;
  }
}
