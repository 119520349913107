.notification-popup {
  position: absolute;
  bottom: 20px;
  right: 0;
  width: 350px;
  margin-right: 15px;
  background-color: #f9f9f9;
  border-radius: 4px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  max-width: 350px;
  z-index: 100;
  transition: box-shadow 0.2s linear;

  .notification-popup-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 5px 10px;

    .notification-popup-announcement {
      margin: 0;
    }

    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 350px;
      height: 1px;
      background-color: #7978781d;
    }

    .dismiss-icon {
      border-radius: 999px;
      padding: 3px;
      &:hover {
        background-color: #d8d6d6a8;
        cursor: pointer;
      }
    }
  }

  .notification-popup-main {
    padding: 10px 15px;

    &:hover {
      cursor: pointer;
      background-color: #edf2ed;
    }

    .notification-popup-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .notification-popup-header__right {
        display: flex;
        align-items: center;
        gap: 0.3rem;
      }

      .notification-popup__date {
        margin: 0;
        width: max-content;
        color: #739570;
        align-self: center;
        display: flex;
        align-items: center;
        gap: 0.3rem;

        &::after {
          content: '';
          display: inline-block;
          height: 3px;
          width: 3px;
          background-color: #739570;
          border-radius: 999px;
        }
      }

      .notification-popup__title {
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: auto;
      }

      .status-icon {
        // position: absolute;
        // top: 20px;
        // right: 20px;
        // background-color: #fff;
        // border-radius: 999px;
        align-self: center;

        &[data-status='0'] {
          color: rgba(114, 114, 224, 0.664);
        }
        &[data-status='1'] {
          color: rgba(236, 90, 90, 0.716);
        }
        &[data-status='2'] {
          color: rgb(178, 178, 3);
        }
        &[data-status='3'] {
          color: #739570;
        }
      }

      .subscription-amount {
        color: #739570;
      }

      h2 {
        font-size: 18px;
        margin-bottom: 10px;
        color: #333;
      }

      p {
        font-size: 14px;
        margin-bottom: 20px;
        color: #666;
      }
    }

    .notification-popup__body {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      margin: 0;
      color: #282727a8;
      padding: 10px 0 0 0;
    }
  }
  @media (max-width: 500px) {
    width: calc(100vw - 30px);
    max-width: unset;
    // left: 0;
  }
}
