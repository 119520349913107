.add-accounts {
  flex-grow: 1;
  overflow-y: scroll;
  padding: 0 3rem;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;

  .content {
    div.actions {
      margin-bottom: 5rem;
      justify-content: center;
      display: flex;
      align-items: center;
      flex-direction: column;

      span.error {
        color: #dc3545;
        font-size: 13px;
        margin-bottom: 10px;
      }

      button.link {
        color: #828282;
        font-size: 14px;
        margin-top: 1rem;
      }
    }
  }

  .material-icons.back-arrow {
    margin-right: auto;
    font-size: 30px;
    display: flex;
    height: 2rem;
    margin-bottom: 2rem;
    cursor: pointer;
  }

  .head {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  h2 {
    margin-bottom: 4px;
  }

  .accounts-box {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 4px;
    margin-top: 2rem;
    margin-bottom: 3rem;
    min-height: 20rem;

    .accounts-box-head {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 1rem 3rem;
      border-bottom: 1px solid #dddcd7;
      input.search-bar {
        margin-left: auto;
      }

      span.material-icons-outlined {
        margin-left: 8px;
        opacity: 0.5;
      }
    }
    table.accounts-table {
      padding: 20px 30px;
      width: 100%;

      .name-column {
        flex-basis: 0;
        justify-content: left;

        span.material-icons-outlined.parent-arrow {
          user-select: none;
        }

        div {
          display: flex;
          align-items: center;
          cursor: default;
        }
        span.highlighted {
          background-color: #cae0c8;
          border-radius: 4px;
        }

        .thumbnail {
          margin-right: 8px;

          & > img {
            border-radius: 50%;
            width: 30px
          }
        }
      }

      .id-column {
        flex-basis: 0;
        justify-content: center;
        text-align: center;
      }

      thead {
        font-weight: 700;
        font-size: 14px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
        border-bottom: 1px solid #f7f6f1;
        display: flex;

        tr {
          display: grid;
          grid-template-columns: auto 10rem;
          flex-grow: 1;

          th {
            display: flex;
          }
        }
      }

      tbody {
        display: flex;
        flex-direction: column;
        padding: 0 10px;
        tr {
          height: 3rem;
          display: grid;
          grid-template-columns: auto 10rem;
          flex-grow: 1;

          td {
            align-items: center;
            display: flex;
            font-size: 100%;

            span.material-icons-outlined.check-box {
              user-select: none;
              font-size: 150%;
              margin-right: 8px;
            }
            span.check-box.check-box-disabled {
              color: #999;
            }
            span.material-icons-outlined {
              cursor: default;
            }
            div.check-box-label-disabled {
              color: #999;
            }

            &.no-accounts {
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }

        span.no-accounts-found {
          text-align: center;
          margin-top: 2rem;
          color: #9b9b9b;
        }
      }
    }
  }
}
