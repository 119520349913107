.products-wrapper {
  display: flex;
  // justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: max-content;

  .products-header {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 60px);
    align-items: center;
    top: 0;
    position: sticky;
    background-color: #fafafa;
    z-index: 1;
    padding: .5rem 3rem;
  }

  .products-top-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .products-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    grid-gap: 2.5rem;
    gap: 2.5rem;
    padding: 2rem 1rem;
    align-self: flex-start;
    margin-left: 2rem;
  }

  .product-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    width: 250px;
    min-height: 440px;
    padding: 20px 20px;

    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 10px 10px 30px 0 rgb(174 174 192 / 40%),
      -10px -10px 30px 0 rgb(221 220 215 / 10%);

    transition: all 0.2s linear;

    position: relative;

    &:hover {
      box-shadow: 0px 10px 20px 0 #70986d24, 0px -10px 20px 0 #a0cd9c1b;
    }

    &[data-featured='true']::before {
      content: 'Most popular';
      position: absolute;
      top: -35px;
      left: 5px;
      right: 150px;
      z-index: -1;

      background-color: #ff9c07;
      padding: 10px 11px;
      text-align: center;

      font-size: 16px;
      font-weight: 700;
    }

    .product-header {
      .product-reccurring {
        color: rgba(159, 156, 156, 0.81);
        font-size: 0.8em;
        text-align: center;
      }

      .product-title {
        color: #1d2c38;
        font-size: 28px;
        font-weight: 700;
        line-height: 37px;
        text-align: center;
      }

      .product-price {
        color: #1e1e1e;
        font-size: 48px;
        font-weight: 700;
        line-height: 59px;

        margin-bottom: 10px;

        display: flex;
        align-items: center;
        justify-content: center;

        &::before {
          content: '€';
          color: #1e1e1e;
          font-size: 16px;
          font-weight: 700;
          line-height: 59px;

          margin-bottom: inherit;
        }

        &:is(.custom)::after {
          content: '';
          color: #1e1e1e;
          font-size: 16px;
          font-weight: 700;
          line-height: 59px;

          margin-top: auto;
        }
        
        &:not(.annually)::after {
          content: '/month';
          color: #1e1e1e;
          font-size: 16px;
          font-weight: 700;
          line-height: 59px;

          margin-top: auto;
        }

        &:not(.monthly)::after {
          content: '/year';
          color: #1e1e1e;
          font-size: 16px;
          font-weight: 700;
          line-height: 59px;

          margin-top: auto;
        }
      }
    }

    .product-body {
      display: flex;
      flex-direction: column;
      align-items: center;

      .product-features {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;

        text-align: center;
        font-size: 12px;
        font-weight: 500;

        height: 160px;
        width: 200px;
        margin: 0;
        overflow-y: scroll;

        &::-webkit-scrollbar {
          display: none;
        }

        transition: all 0.2s linear;

        &.open {
          height: max-content;
        }

        .feature {
          display: flex;
          align-items: center;
          justify-content: space-between;
          text-align: center;
          gap: 0.5rem;
          height: 30px;

          > p {
            font-weight: bold;
          }

          width: 100%;

          div {
            min-width: 24px;
          }
        }
      }

      .product-features.open + .show-button {
        box-shadow: none;
      }

      .show-button {
        background: #f5f5f5;
        font-weight: 700;

        box-shadow: -1px -13px 6px -15px rgba(0, 0, 0, 0.336);
        -webkit-box-shadow: -1px -13px 6px -15px rgba(0, 0, 0, 0.336);
        -moz-box-shadow: -1px -13px 6px -15px rgba(0, 0, 0, 0.336);
        border: none;
        width: 250px;
        position: relative;
        padding: 20px 10px;

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.3rem;

        // margin-top: -25px;

        cursor: pointer;

        span.rotate {
          transform-origin: center;
          transform: rotateZ(180deg);
        }
      }

      .button-wrapper {
        position: sticky;
        bottom: 0;
        border-radius: 3px;

        width: 100%;
        padding: 10px;
        background-color: #f5f5f5;
      }

      .product-button {
        button {
          background-color: #739570;
          font-size: 16px;
          font-weight: 600;
          color: #fff;
          cursor: pointer;

          border: 1px solid #739570;
          border-radius: 3px;

          width: 100%;
          padding: 7px 30px;

          transition: all 0.1s linear;

          &.disabled button {
            background-color: darkgray;  
            border: 1px solid transparent;
          }
          
          &:hover:not(.disabled) {
            background-color: #f3f3f3;
            border: 1px solid #739570;
            color: #739570;
          }

          &:hover .spinner-border {
            color: #739570;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 950px) {
  .products-wrapper {
    flex-direction: column;
    justify-content: center;

    .products-header {
      flex-direction: column;
    }
    
  }

  .products-top-header {
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
  }
}
