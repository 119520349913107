.organization-settings-page {
  padding: 2rem 3rem;

  display: flex;
  flex-direction: column;
}

table.invites {
  padding: 10px;
  padding-bottom: 0;
  border: 1px solid #dddcd7;
  border-radius: 4px;
  background-color: white;
  display: flex;
  flex-direction: column;

  thead {
    tr {
      border-bottom: 1px solid #dddcd7;
      padding-bottom: 7px;
    }
  }

  tr {
    padding: 0 5px;

    .members-column {
      display: flex;
      align-items: left;
    }
  }

  tbody {
    min-height: 3rem;

    tr.no-members {
      display: flex;
      width: calc(100% - 10px);
      justify-content: center;
      height: 3rem;

      td {
        display: flex;
        align-items: center;
      }
    }

    tr.member {
      height: 25px;
      padding-top: 3px;
      padding-bottom: 3px;
      border-bottom: 1px solid #f7f6f1;
      font-size: 14px;

      &:last-child {
        border-bottom: none;
      }

      td {
        display: flex;
        align-items: center;
      }

      td.bin-column {
        justify-content: center;
        position: relative;

        span.material-icons-outlined.bin {
          opacity: 0.6;
          font-size: 22px;
          cursor: pointer;

          &:hover {
            opacity: 1;
          }
        }

        span.material-icons-outlined.error {
          position: absolute;
          color: #d96249;
          right: 0;
          font-size: 20px;
        }

        div.spinner-border {
          width: 10px;
          height: 10px;
          border-width: 0.2em;
        }
      }

      td.status-column {
        display: flex;
        align-items: center;
        justify-content: center;
        div.status-circle {
          margin: 0;
          height: 10px;
          width: 10px;
          border-radius: 50%;
          display: inline-block;

          &.pending {
            background-color: #f7d165;
          }

          &.success {
            background-color: #749770;
          }
        }

        span.status-text {
          font-size: 9px;
          color: #9b9b9b;
          margin-left: 5px;
        }

        .spinner-border {
          width: 0.7rem;
          height: 0.7rem;
          border-width: 0.17em;
        }
      }
    }
  }
}

div.invite-member {
  border: 1px solid #dddcd7;
  border-radius: 4px;
  background-color: white;
  height: 2.5rem;
  padding-right: 15px;
  margin-top: 1rem;
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  overflow: hidden;
  min-height: 2.5rem;

  input {
    height: 100%;
    margin: 0;
    padding-left: 15px;
    border: none;
    outline: none;
    width: 100%;
    font-weight: 700;
  }

  div.actions {
    width: 4rem;

    div.spinner-border {
      width: 0.7rem;
      height: 0.7rem;
      border-width: 0.17em;
    }
  }

  button {
    right: 0;
    top: 0;
    width: 4rem;
    height: 1.7rem;
    font-size: 11px;
    margin: 0;
  }
}
