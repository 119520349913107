.data-load-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  align-items: center;
}

div.data-load {
  .next-etl-time {
    margin-top: 30px;
    width: 300px;
    button.run-etl-button {
      text-decoration: none;
      width: 16rem;
      height: 2rem;
      background-color: white;
      margin-top: auto;
      border: 0;
      border-radius: 4px;
      color: white;
      font-weight: 400;
      font-size: 13px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #dddcd7;
      width: 100%;
      color: black;

      cursor: pointer;

      &:focus {
        outline-color: #6a8a67;
      }

      &:hover {
        border: 1px solid black;
      }

      &:disabled {
        cursor: auto;
        border: 1px solid #dddcd7;
        background-color: #dddcd7;
      }
    }
    .started-at {
      color: #9b9b9b;
      font-size: 0.8em;
    }
  }
}

.logs-container {
  h2 {
    margin: 3rem;
    margin-bottom: 2rem;
  }
}
