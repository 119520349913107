.pane-containers {
  display: flex;
  align-items: center;
  margin-top: 1em;
}

.options-pane {
  border-right: 1px solid #dddcd7;
  width: max-content;
  height: 100%;

  h2 {
    margin-right: 3em;
  }

  .options {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    width: 100%;
    border-top: 1px solid #dddcd7;

    li {
      cursor: pointer;
      padding: 7px 0;
      width: 100%;
      border-bottom: 1px solid #dddcd7;
      border-right: none;
      border-left: none;

      &:hover,
      &.selected {
        background-image: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(193, 222, 190, 0.2399160348) 100%
        );
      }
    }
  }
}

.pane-body {
  margin-left: 3em;

  p {
    margin: 0;
  }

  .status {
    display: flex;
    align-items: center;
    gap: 1em;
    .submit-button {
      margin-top: 0;
    }
  }
  .share-status {
    display: flex;
    align-items: center;

    p {
      width: max-content;
    }
    .success {
      color: green;
    }

    .fail {
      color: red;
    }
  }
}
