.clusters-campaigns {
  padding: 4rem 3rem;
  padding-top: 2rem;
  padding-bottom: 0;

  .add-column {
    margin-right: auto;
    margin-bottom: 4rem;
    margin-top: 10px;
    font-size: 14px;
    display: flex;
    align-items: center;

    span.enlarge {
      margin-top: -4px;
    }
  }

  div.draggable-column {
    margin-top: 1rem;

    div.drag-handle-container {
      width: 50px;

      span.material-icons-outlined.drag-handle {
        user-select: none;
        opacity: 0.4;
      }
    }
  }

  div.column {
    width: calc(100% - 60px);
    background-color: white;
    border-radius: 4px;
    padding: 15px 30px;
    padding-left: 15px;
    min-width: 20rem;
    height: 3rem;
    min-height: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.with-subtitle {
      min-height: 3.7rem;
      padding-bottom: 10px;
    }

    div.column-main {
      display: flex;
      flex-direction: row;
    }

    div.subtitle-section {
      margin-left: 5px;
      padding-left: 10px;
      margin-right: -10px;
      padding-right: 10px;
      margin-top: 6px;
      padding-top: 4px;
      font-weight: 400;
      font-size: 12px;
      color: #9b9b9b;

      span.black {
        color: black;
      }
    }

    &.is-not-draggable {
      margin-top: 1rem;
      div.subtitle-section {
        margin-left: -10px;
      }
      div.info {
        // padding-left: 50px;
      }
    }

    div.info {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .title {
        margin-bottom: 3px;
        font-weight: 400;
      }

      a.name {
        color: black;
        text-decoration: none;
        font-weight: 700;

        &:hover {
          color: #739570;

        }
      }
    }

    div.actions {
      display: flex;
      align-items: center;
      justify-content: center;

      div.delete {
        position: relative;

        div.confirm-delete {
          top: -60px;
          left: 0;
          position: absolute;
          width: 80px;
          height: 40px;
          padding: 5px 5px;
          border: 1px solid #dddcd7;
          border-radius: 4px;
          background-color: white;
          display: flex;
          flex-direction: column;
          align-items: center;

          span.sure {
            font-size: 11px;
          }

          span.delete {
            margin-top: 5px;
            font-size: 14px;
            color: #dc3545;
            cursor: pointer;
          }

          span.material-icons-outlined.arrow {
            color: #dddcd7;
            position: absolute;
            bottom: -15px;
            left: 0;
          }
        }

        span.material-icons-outlined.bin {
          font-size: 27px;
          opacity: 0.5;
          cursor: pointer;

          &:hover {
            opacity: 0.8;
          }
        }
      }

      .edit-button {
        margin-left: auto;
        font-weight: 700;
        font-size: 12px;
      }
    }
  }
}

div.clusters-overview-campaigns {
  div.column.is-not-draggable {
    div.info {
      padding-left: 50px;
    }
  }
}
