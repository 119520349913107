div.container-column {
  &.sign-up {
    margin: 0 calc(7rem - 10px);
    margin-top: 30px;
    margin-bottom: 0;

    .sign-up-method-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 0.4rem;
      margin-bottom: 2rem;
    }
  }

  .submit-button {
    margin-bottom: 20px;
  }
}

div.sign-up-box {
  background-color: white;
  width: calc(35rem - 20px);
  margin-top: 1rem;
  height: 38rem;
  border-radius: 12px;
  padding: 0 10px;
  padding-top: 2rem;
  margin-bottom: 2rem;

  h2 {
    margin-top: 20px;
    margin-bottom: 12px;
  }
}

div.forgot-password-box {
  background-color: white;
  width: calc(33rem - 20px);
  margin-top: 1rem;
  height: 34rem;
  border-radius: 12px;
  padding: 0 10px;
  padding-top: 2rem;
  margin-bottom: 2rem;

  h2 {
    margin-top: 20px;
    margin-bottom: 12px;
  }
}

.sign-up-footer {
  border-top: 1px solid #dddcd7;
  padding: 20px 0;
}

.step-progress-bar {
  grid-column: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.drop-down-select {
  margin-top: 3rem;
}

div.terms {
  margin: 0 20px;
  margin-top: 2rem;

  div.terms-box {
    margin-bottom: 0.8rem;
    height: 14rem;
    border-radius: 4px;
    border: 1px solid #dddcd7;
    overflow-y: scroll;
    padding: 0 1rem;
    color: #9b9b9b;
    font-size: 12px;

    table.in-terms-and-conditions {
      border-collapse: collapse;
      tr {
        td {
          border: 1px solid #9b9b9b;
          padding: 2px 7px;
        }
      }
    }

    &:hover {
      border-color: #c4c3be;
    }
  }
}

a.no-accept {
  margin-top: 1.5rem;
  color: #443f3f;
  font-size: 14px;
  text-decoration: none;

  &:hover {
    color: #808080;
  }
}

.material-icons.back-arrow.sign-up {
  position: absolute;
  cursor: pointer;
  margin-left: 20px;
  margin-top: -7px;
  font-size: 30px;
}

// new

.sign-up {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.header-container {
  justify-content: center;
  width: 100%;
}

.sign-up-container {
  background-color: #fff;
  padding: 50px 0;
  margin: 30px 0;
  width: 50%;
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.6em;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  #root {
    background-color: #fff;
  }

  .hide-mobile {
    display: none;
  }

  .header-container {
    padding: 0;
    .logo {
      grid-column: 2;
      justify-content: center;
      padding-top: 0;
    }
  }

  .sign-up-container {
    margin: 0;
    padding: 10px 0;
    height: 800px;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  div.container-column {
    &.sign-up {
      margin: 0;
      margin-top: 30px;
      margin-bottom: 0;

      padding: 0 20px;
    }

    .submit-button {
      margin-bottom: 20px;
    }
  }

  .form-wrapper {
    gap: 3em;
  }

  body {
    overflow: hidden;
  }
}
