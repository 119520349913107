.toolbar {
  display: none;
  position: sticky;
  bottom: 20px;
  justify-content: center;
  max-height: 250px;

  background-color: white;
  box-shadow: 0.3px 0.7px 2.2px rgba(0, 0, 0, 0.02),
    0.7px 1.8px 5.3px rgba(0, 0, 0, 0.028),
    1.4px 3.4px 10px rgba(0, 0, 0, 0.035), 2.5px 6px 17.9px rgba(0, 0, 0, 0.042),
    4.6px 11.3px 33.4px rgba(0, 0, 0, 0.05), 11px 27px 80px rgba(0, 0, 0, 0.07);
  font-size: 14px;
  border-radius: 4px;

  &:has(.submenu) {
    &:has(.filters-submenu) {
      max-height: none;
      bottom: 0;
    }
  }

  .pb-btn {
    border: none;
    background-color: white;
    padding: 5px 5px;

    display: flex;
    align-items: center;
    gap: 3px;

    &:hover {
      cursor: pointer;
      background-color: #f2f2f2;
    }
  }

  .toolbar-option {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 10px;
    width: 60px;

    div:nth-child(2) {
      text-align: center;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover {
      cursor: pointer;
      background-color: #f2f2f2;
    }
  }

  .submenu {
    overflow: scroll;
    min-width: 320px;
    min-height: 65px;

    > .submenu-back {
      display: flex;
      align-items: center;
      gap: 0.7rem;
      cursor: pointer;
      padding: 5px;
      background-color: white;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;

      z-index: 9999;
      position: sticky;
      top: 0;

      &:hover {
        background-color: #f2f2f2;
      }
    }

    &:has(.filters-submenu) {
      height: calc(100vh - 38px);
      width: 100vw;

      .filters-submenu {
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 6px;
      }
    }

    .filters-submenu {
      padding: 8px;

      .filter {
        border: 1px solid rgb(200, 200, 200);
        border-radius: 2px;
        box-shadow: 0 0 2px rgb(0 0 0 / 20%);
        padding: 4px;
        padding-left: 8px;

        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;

        .date-filter,
        .categorical-filter {
          width: 100%;
          .date-filter-expanded,
          .categorical-filter-expanded {
            width: calc(100% - 12px);
          }

          .date-filter-expanded {
            .date-filter-view {
              > p + div {
                display: flex;
                flex-direction: column;
                gap: 4px;
                align-items: flex-start;
              }

              input::-webkit-calendar-picker-indicator {
                padding: 5px;
                cursor: pointer;

                &:hover {
                  opacity: 0.6;
                }
              }
            }
          }
        }

        &.has-filter {
          background-color: rgb(243, 242, 241);
        }

        p {
          margin: 0;
        }

        P:has(+ .pb-dropdown) {
          margin-bottom: 4px;
        }

        div.filter-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;

          > p.filter-title {
            font-weight: bold;
          }
        }
      }
    }

    .bookmark-category {
      display: flex;
      align-items: center;
      justify-content: space-between;

      padding: 0 5px 0 5px;
      font-weight: bold;

      cursor: pointer;
    }

    .capture-btn {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 9999;
      border-top-right-radius: 4px;
    }

    .submenu-pages,
    .submenu-bookmarks {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;

      li {
        list-style: none;
        padding: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0.6rem;

        > div {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &.collapsed {
          + .bookmarks-submenu {
            display: none;
          }
        }

        span {
          scale: 0.8;
        }

        &:hover {
          cursor: pointer;
          background-color: #f2f2f2;
        }

        &.active {
          background-color: #d9d9d9be;
          font-weight: bold;
        }
      }

      .bookmarks-submenu {
        padding: 0;

        li {
          padding: 8px 8px 8px 35px;
        }
      }
    }
  }
}

.pb-input-field {
  color: rgb(37, 36, 35);
  border: 1px solid rgb(200 200 200);
  border-radius: 2px;
  width: 100%;
  padding: 3px;
  min-height: 24px;
  background-color: transparent;

  outline: none;
}

.pb-checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;

  .label {
    font-size: 1.2em;
    margin: 0 10px;
  }

  .checkmark {
    width: 16px;
    height: 16px;
    border: 1px solid rgb(37, 36, 35);
    display: inline-block;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 32 32' version='1.1'><g id='surface1'><path style='' d='M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z '/></g></svg>")
      center/1250% no-repeat;

    &.partial-check {
      background-image: url('https://upload.wikimedia.org/wikipedia/commons/2/21/Solid_black.svg');
      background-size: 80% !important;
    }
  }

  span.checkmark.check {
    background-size: 100%;
  }

  input {
    display: none;
  }

  * {
    box-sizing: border-box;
  }
}

.pb-dropdown {
  width: 100%;
  border: 1px solid rgb(200, 200, 200);
  border-radius: 2px;
  font-size: 12px;
  color: rgb(37, 36, 35);
  padding: 3px;
  position: relative;

  .pb-dropdown-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    > p {
      padding-left: 3px;
      margin: 0;
    }
  }

  ul.pb-dropdown-content {
    position: absolute;
    top: 20px;
    background-color: white;
    box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%),
      0 1px 10px 0 rgb(0 0 0 / 12%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0;
    width: calc(100% - 6px);
    z-index: 9999;

    li {
      padding: 7px 6px 9px;
      list-style: none;
      width: calc(100% - 12px);

      &.selected {
        background-color: #8a8886;
      }

      &:hover {
        background-color: rgb(200, 200, 200);
        cursor: pointer;
      }
    }
  }
}

.pb-multi-select {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .pb-multi-select-option {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 2px 0 0 8px;
    gap: 12px;

    span:hover {
      color: rgb(88 85 83);
      cursor: pointer;
    }

    span.truncate {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.pb-search-field {
  color: rgb(37, 36, 35);
  border-color: rgb(200, 200, 200);
  background: rgb(255, 255, 255);
  height: 12px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-width: 1px;
  border-bottom: 1px solid #c8c8c8;
  min-height: 20px;
  margin: 4px 0;
  padding: 4px 3px;

  > span {
    color: rgb(37 36 35 / 0.5);
    transform: rotateY(180deg);
  }

  input {
    background: rgb(255, 255, 255);
    color: rgb(37, 36, 35);
    font-size: 12px;
    border: none;
    outline: none;
  }
}

.pb-apply-button {
  background: transparent;
  border: none;
  color: rgb(37, 36, 35);
  cursor: pointer;

  &.disabled {
    cursor: default;
    color: rgba(37, 36, 35, 0.147);
  }
}

@media screen and (max-width: 768px) {
  .toolbar {
    display: flex;
  }
}
