//   guide
.upload-guide {
  transition: transform 0.2s ease-in-out;

  padding: 0 2rem 2rem 2rem;
  border-radius: 10px;
  overflow-y: hidden;

  height: 200px;

  h1 {
    text-align: center;
  }

  &.show {
    transform: translateX(0%);
  }

  .step-heading {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .step-heading p {
    background-color: #739570;
    color: #fff;
    font-weight: bold;
    font-size: 0.7rem;

    padding: 10px 20px;
    border-radius: 30px;

    width: max-content;
  }

  ol {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    li {
      line-height: 30px;
    }

    li a {
      text-decoration: none;
    }

    li a span {
      vertical-align: bottom;
      margin-bottom: 0.25rem;
    }

    code {
      background-color: rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      padding: 0.2em 0.5em;
    }

    .img-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 1rem;
      margin-bottom: 1rem;

      img {
        border-radius: 5px;
        border: 1px solid #dfe1e5;
        width: 100%;
        height: auto;
      }
    }
  }
}
