.demo, .dashboard {
  flex-grow: 1;
  overflow-y: hidden;
  padding: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  div.embed-info-error {
    display: flex;
    flex-grow: 1;

    div.box {
      margin: auto;
      padding: 10rem 5rem;
      width: 20rem;
      height: 15rem;
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: center;

      h1 {
        text-align: center;
      }

      div {
        text-align: center;
      }

      button {
        margin-top: 3rem;
        width: 10rem;
      }
    }
  }

  .head {
    display: flex;

    button {
      margin-left: auto;
      width: 10rem;

      span.enlarge {
        margin-bottom: 4px;
        margin-right: 10px;
        font-weight: 400;
      }
    }
  }

  h2 {
    margin-bottom: 4px;
  }

  nav {
    ul {
      display: flex;
      flex-direction: row;
      list-style-type: none;
      padding: 0;

      li {
        margin-left: 3rem;

        &:first-child {
          margin-left: 0;
        }

        &.selected {
          color: #739570;
          font-weight: 700;

          &::after {
            content: '';
            height: 3px;
            background: #739570;
            border-radius: 10px;
            display: block;
          }
        }
      }
    }
  }

  div.content {
    display: flex;
    flex-direction: column;
  }

  #report-container {
    width: calc(100% - 6px);
    height: calc(100vh - 8px);
  }

  .embed-container {
    width: calc(100% - 6px);
    height: calc(100vh - 8px);

    iframe {
      border: none;
    }
  }
}
