.billing-details-page {
  .loading-details {
    opacity: 0.3;
  }
  .form-error {
    font-size: 0.8em;
    color: red;
  }
  .billing-details-form {
    width: 50%;
    margin-top: 20px;
    .actions {
      margin-top: 40px;
      display: flex;
      flex-direction: row;
      button {
        margin-right: 40px;
      }
      .save-button {
        background-color: #fff;
        border: 1px solid #eee;
        color: black;
      }
      .checkout-button {
      }
      .saved-icon {
        margin-top: 5px;
      }
      .disabled-button {
        opacity: 0.5;
      }
    }
  }
}
