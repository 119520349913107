.add-source {
  flex-grow: 1;
  overflow-y: auto;
  padding: 0 3rem;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;

  .material-icons.back-arrow {
    margin-right: auto;
    font-size: 30px;
    display: flex;
    height: 2rem;
    margin-bottom: 2rem;
    cursor: pointer;
  }

  .head {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  h2 {
    margin-bottom: 4px;
  }

  .integrations-category {
    border: 0;
    margin-bottom: 0;
    .services {
      padding: 12px 0;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 1rem; 

      .source-button {
        cursor: pointer;
        width: 8rem;
        height: 8rem;
        background-color: white;
        border-radius: 4px;
        border: 1px solid #dddcd7;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 0.5rem 0.7rem;
        overflow: hidden;


        &:hover:not(.disabled) {
          border: 1px solid #c2c1bc;
        }

        &:first-child {
          margin-left: 0;
        }

        .info {
          flex-grow: 2;
          flex-basis: 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          padding: 15px 0;

          .spinner-border {
            border-width: 0.2em;
            width: 1rem;
            height: 1rem;
          }

          img {
            max-width: 90px;
            max-height: 40px;
            margin-bottom: 12px;
            width: inherit;
          }
          span.name {
            font-size: 13px;
            font-weight: 400;
            text-align: center;
          }
        }

        button.link {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-grow: 0.5;
          flex-basis: 0;
          border-top: 1px solid #f7f6f1;

          .enlarge {
            margin-bottom: 5px;
            margin-right: 3px;
          }
        }
      }
      .source-button.disabled {
        border-color: #dddcd797;

        img, .link{
          opacity: 0.2;
        }

        .name {
          opacity: .6
        }

        .upgrade-btn {
          opacity: 1;
          background-color: #739570;
          color: white;
          border-radius: 3px;
          border: 1px solid #739570;
          padding: 2px;
          min-height: 35px;

          &:hover {
            background-color: white;
            border-color: #739570;
            color: #739570;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .add-source {
    // padding: 0 1rem;
    // padding-top: 2rem;

    .head {
      width: 100%;
    }

    .integrations-category {
      padding: 1rem 0;

      // setup a grid in .services that has x columns and a varying gap between them up to a max of 3rem and a min of 1rem
      // if the screen is to small and only one column can fit then increase the width of the .source-button to 100vw

      .services {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(8.8rem, 1fr));
        gap: 1rem;
        grid-gap: 1rem;
      }
      
    }
    
  }


  
}

// 8.8rem for each source button + 1rem for the gap between them = 18.6rem plus 96px for the padding on the sides of the .add-source container
@media screen and (max-width: calc(18.6rem + 96px)) {
  .add-source {
    .integrations-category {
      .services {
        .source-button {
          width: 100%;
        }
      }
    }
  }
}