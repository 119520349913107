.unshare-dataset-table {
  border: 1px solid #dddcd7;
  border-radius: 4px;
  background-color: white;
  padding: 10px;
  width: 30rem;
  margin-top: 10px;

  thead {
    tr {
      width: 100%;

      th {
        border-bottom: 1px solid #dddcd7;
        &:nth-of-type(1) {
          text-align: start;
        }
        &:last-of-type {
          text-align: end;
        }
      }
    }
  }

  tbody {
    tr {
      td:last-of-type {
        text-align: end;
      }
    }

    span {
      cursor: pointer;
      &:hover {
        color: red;
      }
    }
  }
}

.share-dataset-container {
  .input-container {
    display: flex;
    justify-content: space-between;

    background-color: white;
    border: 1px solid #dddcd7;
    border-radius: 4px;
    width: 30rem;
    height: 40px;

    .share-input {
      padding-left: 15px;

      border: none;
      background-color: transparent;
      outline: none;
      width: 100%;

      &::placeholder {
        font-weight: 700;
      }
    }

    .share-button {
      background-color: #739570;
      color: white;
      border: none;
      border-radius: 4px;
      padding: 6px 15px;
      margin: 6px;
      font-size: 11px;
      font-weight: 700;
      cursor: pointer;
    }
  }
}
