div.competitors {
  position: relative;

  span.material-icons-outlined.back-arrow {
    position: absolute;
    top: 20px;
    font-size: 30px;
    cursor: pointer;
    color: black;
  }

  div.content {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;

    div.save-button {
      margin-top: 4rem;
      margin-bottom: 2rem;
      width: 16rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      span.save-error {
        color: #dc3545;
        font-size: 13px;
        margin-bottom: 10px;
      }

      button.submit-button {
        margin: 0;
      }
    }

    div.row {
      display: flex;

      .alert {
        margin: 0;
        width: 100%;
      }

      &.search-terms {
        margin-top: 2rem;
        margin-bottom: 1rem;
      }

      div.account {
        width: 24rem;
        height: 4.3rem;
        display: flex;
        align-items: center;
      }

      div.country {
        width: 14rem;
        margin-left: 5rem;
        height: 4.3rem;
      }

      div.competitors-column {
        width: 14rem;
        height: 3.7rem;
        display: flex;
        align-items: center;
      }

      div.competitors-value {
        width: 14rem;
        margin-left: 5rem;
        height: 3.7rem;
        display: flex;
        align-items: center;
      }

      div.search-by {
        width: 14rem;
        height: 3.7rem;
      }

      div.search-count {
        width: 14rem;
        margin-left: 5rem;
        height: 3.7rem;
      }
    }

    span.search-terms {
      margin-top: 2rem;
      font-weight: 700;
      font-size: 13px;
    }
  }
}
