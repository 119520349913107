.notification-bell {
  position: relative;
  button.bell {
    border: none;
    background: none;
    transition: all 0.1s linear;
    cursor: pointer;
    filter: opacity(0.7);
    position: relative;

    &:hover {
      filter: opacity(1);
    }

    &[data-new-notifications]:not([data-new-notifications='0'])::after {
      content: attr(data-new-notifications);
      color: #fff;
      font-size: 11px;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      width: 14px;
      height: 14px;
      border-radius: 999px;
      background-color: red;
      position: absolute;
      top: 0px;
      right: 4px;
    }
  }

  .notification-menu {
    background-color: #fff;
    box-shadow: 0px 4px 10px rgba(#000, 0.18);
    border-radius: 4px;
    width: 250px;
    height: max-content;
    max-height: 300px;
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .notification-container {
      padding: 2px;
      width: 100%;
      overflow-y: auto;
      scrollbar-gutter: auto;

      h2 {
        font-size: 18px;
      }

      .no-notifications {
        color: #282727a8;
        font-size: 13px;
      }

      .notification-list {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        list-style: none;
        padding: 0;

        .notification {
          position: relative;
          &:is([data-unread='true']) {
            & > .item {
              background-color: #edf2ed;
              border-color: rgba(0, 0, 0, 0.03);
            }
          }

          .item {
            border: 1px solid rgba(0, 0, 0, 0.07);
            height: 50px;
            border-radius: 4px;
            padding: 0 8px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            transition: border 0.1s linear;

            &:hover,
            &[data-status='2']:hover,
            &[data-status='3']:hover {
              cursor: pointer;
              border-color: rgba(0, 0, 0, 0.4);
              color: #000;
            }

            &[data-status='2'],
            &[data-status='3'] {
              background-color: #fff;
              color: #00000088;
            }

            button.action-btn {
              border: none;
              background: none;
              padding: 2px 4px;
              scale: 0.8;
              cursor: pointer;
              aspect-ratio: 1;
              display: flex;
              align-items: center;
              border-radius: 999px;

              &:hover {
                background-color: #73957072;
              }
            }

            .notification-item__header {
              display: flex;
              flex-direction: column;
              gap: 0.1rem;
              font-size: 12px;
              width: 80%;
              justify-content: center;

              & > p {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
                margin: 0;
              }

              .notification-item__title {
              }

              .notification-item__info {
                display: flex;
                align-items: center;
                height: 20%;

                span.material-icons-outlined,
                span.material-icons {
                  scale: 0.6;
                  border-radius: 999px;

                  &[data-status='0'] {
                    color: rgba(114, 114, 224, 0.664);
                  }
                  &[data-status='1'] {
                    color: rgba(236, 90, 90, 0.716);
                  }
                  &[data-status='2'] {
                    color: rgb(178, 178, 3);
                  }
                  &[data-status='3'] {
                    color: #739570;
                  }
                }

                .notification-item__date {
                  font-size: 12px;
                  color: #739570;
                  margin: 0;
                  // needed for circle to align properly
                  display: flex;
                  align-items: center;
                  gap: 0.2rem;

                  &::before {
                    content: '';
                    display: inline-block;
                    height: 3px;
                    width: 3px;
                    background-color: #739570;
                    border-radius: 999px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
