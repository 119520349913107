a {
  color: #739570;
  cursor: pointer;

  &:hover {
    color: #6a8a67;
  }
}

.link {
  color: #739570;
  cursor: pointer;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  font-weight: inherit;
  outline: none;
  text-decoration: none;

  &:hover {
    color: #6a8a67;
  }
}

h1 {
  font-size: 32px;
  margin-bottom: 12px;
  font-weight: 700;
}

h2 {
  font-size: 24px;
  margin: 0;
  font-weight: 700;
}

h3 {
  font-size: 19px;
  margin-bottom: 12px;
  font-weight: 700;
}

div.secondary-text {
  font-weight: 700;
  font-size: 14px;
}

.subtitle-text {
  color: #9b9b9b;
  font-size: 13px;
}

.text-align-center {
  text-align: center;
}

.non-selectable {
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.enlarge {
  font-size: 180%;
}

span.text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.info-context {
  font-size: 0.8em;
  color: #999;
}
