.alert-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  padding: 0 20%;
  line-height: 20px;

  & > .alert-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3rem;
    text-align: center;
    background-color: white;
    height: 65%;
    width:max-content;
    gap: 0.5rem;
    border-radius: 12px;
    // box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    font-size: 14px;

    h4 {
        font-weight: 300;
    }

    .password-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 10px;
    }

    .warn {
        color: #f15454;
    }

    .accounts-box {
        display: flex;
        flex-direction: column;
        gap:.5rem;


        .account {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width:20vw;
            border: 1px solid black;
            padding: 0 5px;
            border-radius: 3px;

            &.valid {
                border-color: #739570;
                background-color: #8da38b36;
            }

            &.invalid {
                border-color: rgb(241, 52, 52);
                background-color: rgba(229, 83, 83, 0.236);
            }

            .head {
                display: flex;
                align-items: center;
                gap:.5rem;
            }

            img {
                height: 30px;
            }
        }
    }

    & > img {
      width: 140px;
    }

    .email {
      color: #739570;
    }

    .resend-link {
      padding-bottom: 20px;
    }

    .button-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 0.5rem;
    }

    .logo-container {
        &:has(:nth-child(n + 2)) :first-child {
                border-right: 1px solid #000000;
                padding-right: 15px;
          }
        
        &>:nth-child(2) {
            padding-left: 18px;
        }

        img {
            height: 50px;
            aspect-ratio: square;
        }
      }
      
    span.provider-name {
        color: #739570;
    }
  }
}

@media screen and (max-width: 767px) {
  .alert-container {
    padding: 0;
    // & > .notification-body {
    //   padding: 2rem;
    //   width: 100%;
    //   height: 100%;
    //   gap: 1rem;
    //   font-size: 12px;

    //   h4 {
    //     font-size: 14px;
    //   }

    //   .accounts-box {
    //     .account {
    //       width: 100%;
    //     }
    //   }

    //   .logo-container {
    //     img {
    //       height: 40px;
    //     }
    //   }
    // }
  }
  
}