.submit-forgot-password {
  width: 16rem;
  height: 2.6rem;
  margin-top: auto;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  button.submit-button {
    width: 100%;
    height: 100%;
    margin: 0;
  }

  div.spinner-border {
    width: 1.2rem;
    height: 1.2rem;
    border-width: 0.18em;
  }

  .material-icons-outlined.has-submitted {
    font-size: 30px;
    color: #739570;
  }
}
